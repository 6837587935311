import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../scss/Page.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';

function CondizioniLegali() {

  let history = useHistory();


  useEffect(() => {

    window.scrollTo(0, 0)

  }, []);

  return (
    <div className="Page">
      <div>
        <Header />
        <div className="cover">
          <h1>Condizioni Legali</h1>
        </div>
        <div className="content">
          <div>
            <div>
              <ol>
                <li>
                  <h3>Informazioni di contatto</h3>
                  <p>Email: info[chiocciola]fanta1.it</p>
                </li>
                <li>
                  <h3>Condizioni generali di utilizzo del portale fanta1.it e informativa agli utenti</h3>
                  <ol type="a">
                    <li>
                      <h4>Preambolo</h4>
                      <p>
                        Il sito web fanta1.it (di seguito potrebbe anche essere definito, il "Sito").
                        L'accesso e l'utilizzo del Sito attribuiscono automaticamente a colui che lo
                        effettua la condizione di Utente. Si considerano Utenti le persone fisiche
                        che utilizzano il Sito in qualità di consumatori in relazione ai servizi
                        offerti da fanta1.it.<br /><br />
                        L'accesso e l'utilizzo del Sito implicano l’accettazione piena e senza
                        riserve degli Utenti del contenuto delle presenti "Condizioni generali
                        di utilizzo", della "Politica sulla protezione dei dati personali", nonché,
                        se applicabili, delle "Condizioni particolari" eventualmente accessorie,
                        sostitutive o modificative in alcun modo delle presenti Condizioni generali
                        di utilizzo rispetto ai servizi e ai contenuti del Sito. L'Utente ha facoltà
                        di accedere, stampare, scaricare e archiviare in ogni momento le presenti
                        Condizioni generali di utilizzo. Le presenti Condizioni generali di utilizzo
                        sono permanentemente consultabili sul Sito accedendo al link "Condizioni Legali".<br /><br />
                        fanta1.it si riserva il diritto di modificare, senza preavviso, il
                        contenuto delle presenti Condizioni generali di utilizzo, si raccomanda
                        pertanto agli Utenti di leggere attentamente il testo delle stesse prima
                        dell’accesso e dell’utilizzo di qualsiasi servizio del Sito.<br /><br />
                        fanta1.it ha altresì la facoltà di modificare, senza preavviso,
                        la realizzazione e la presentazione grafica e/o la configurazione del
                        Sito, nonché alcuni o tutti i servizi o le funzionalità dello stesso,
                        con facoltà di sopprimerli e/o aggiungerne degli altri.<br /><br />
                        fanta1.it si riserva comunque il diritto, in qualsiasi momento
                        e senza preavviso, di negare l'accesso al Sito agli Utenti che violino
                        una qualsiasi delle presenti Condizioni generali di utilizzo, delle
                        Condizioni particolari eventualmente applicabili, o che, a discrezione
                        di fanta1.it, non utilizzino correttamente il Sito o ne abbiano
                        tratto vantaggio in modo unilaterale per un tempo determinato.
                      </p>
                    </li>
                    <li>
                      <h4>Oggetto</h4>
                      <p>
                        Le presenti Condizioni generali di utilizzo disciplinano l'accesso e
                        l'utilizzo del Sito, il cui scopo è quello di divertimento e ironia riguardo
                        le gare di Formula 1.<br /><br />
                        A tal fine, fanta1.it mette a disposizione degli utenti una serie
                        di strumenti per poter comporre una propria squadra di Formula 1 e accumulare quindi,
                        punti gara per gara.<br /><br />
                        fanta1.it si impegna ad aggiornare i risultati di gara entro e non oltre
                        l'inizio della gara successiva.<br /><br />
                        fanta1.it si riserva il diritto di rimuovere dal Sito,
                        senza preavviso, ogni genere di contenuti ritenuti contrari
                        alle normative vigenti o che violino o siano suscettibili di
                        violare eventuali diritti di terzi, nonché quei contenuti che
                        possano ritenersi inappropriati, inadeguati o che non soddisfino
                        i requisiti minimi di qualità desiderati.<br /><br />
                        L'Utente prende atto dell’impossibilità tecnica di garantire
                        una disponibilità al 100% del Sito. Ciononostante, fanta1.it
                        si impegna a rendere disponibile il Sito nella forma più
                        continuativa possibile. Per particolari ragioni di manutenzione,
                        sicurezza o capacità, nonché con motivo di eventi estranei al
                        controllo di fanta1.it (ad esempio, malfunzionamento di
                        reti pubbliche di comunicazioni, interruzioni di corrente, ecc.),
                        è possibile che si verifichino brevi anomalie o la sospensione
                        temporanea dei servizi del Sito.<br /><br />
                        fanta1.it si riserva il diritto di eliminare unilateralmente
                        e senza preavviso l’account degli Utenti che siano stati oggetto di
                        ripetuti reclami di cui fanta1.it sia venuta a conoscenza.
                      </p>
                    </li>
                    <li>
                      <h4>Obblighi degli Utenti del Sito</h4>
                      <p>
                        L'Utente si impegna a fare un uso diligente del Sito e dei servizi
                        accessibili sullo stesso, in piena osservanza della legge, dei buoni
                        costumi e delle presenti Condizioni generali di utilizzo, delle
                        Condizioni particolari eventualmente applicabili, nonché a trattare
                        con il dovuto rispetto gli altri Utenti. Ai sensi del paragrafo
                        quarto della clausola 2.a di cui sopra, fanta1.it si riserva
                        il diritto di ritirare qualsiasi contenuto che, a sua discrezione,
                        non soddisfi i requisiti minimi di qualità.<br /><br />
                        <br /><br />
                        Tutte le informazioni fornite dall'Utente, con qualsiasi meccanismo
                        abilitato ad ottenere la prestazione dei servizi offerti da fanta1.it,
                        devono essere veritiere ed esatte. Agli effetti delle presenti Condizioni Legali,
                        l'Utente garantisce l'autenticità di tutti i dati comunicati in seguito
                        alla compilazione dei moduli o delle risposte a richieste effettuate
                        da fanta1.it e che risultino necessarie per la sottoscrizione
                        ai Servizi.<br /><br />
                        È altresì responsabilità dell'Utente mantenere tutte le informazioni
                        fornite a fanta1.it permanentemente aggiornate, in modo da
                        riflettere in ogni momento la situazione reale dell'Utente. In ogni
                        caso, l'Utente è l’unico responsabile in caso di dichiarazioni false
                        o inesatte rese, nonché di informazioni false o inesatte fornite, per
                        eventuali danni e perdite cagionati a fanta1.it o a terzi per tale motivo.<br /><br />
                        L'Utente è tenuto a rispettare le normative vigenti e i
                        diritti di terzi durante l’utilizzo dei contenuti e dei servizi
                        del Sito. È inoltre vietata la riproduzione, distribuzione,
                        trasmissione, adattamento o modifica, con qualsiasi mezzo e in qualsiasi
                        forma, dei contenuti del Sito (testi, disegni, grafica, informazioni,
                        database, file audio e/o video, loghi, ecc.) e altri elementi del
                        presente Sito, salvo previa autorizzazione dei legittimi proprietari
                        o se consentito dalla legge.<br /><br />
                        In particolare, all'Utente è fatto divieto di: utilizzare contenuti
                        offensivi o diffamatori, indipendentemente dal fatto che tali contenuti
                        riguardino altri Utenti o altre persone fisiche o giuridiche; utilizzare
                        contenuti pornografici o suscettibili di violare le normative vigenti a
                        tutela dei minori o pubblicizzare, offrire o distribuire prodotti
                        pornografici o suscettibili di violare dette leggi a tutela dei minori;
                        molestare o danneggiare altri Utenti o terzi (in particolare, per mezzo di spam);
                        utilizzare contenuti legalmente protetti (ad esempio, dalle leggi sulla
                        tutela di proprietà intellettuale, marchi, brevetti, modelli di utilità
                        o modelli estetici) senza averne il diritto, o pubblicizzare, offrire
                        o distribuire beni o servizi legalmente protetti; nonché commettere
                        o istigare atti contrari alla libera concorrenza, inclusi quelli
                        diretti alla captazione massiva di clienti (come sistemi a
                        effetto valanga, catene di sant'Antonio, sistemi piramidali
                        o altri). È fatto altresì divieto agli Utenti, in qualità di
                        consumatori, di promuovere beni e servizi a proprio esclusivo
                        vantaggio o a quello di persone a loro vincolate.<br /><br />
                        È fatto divieto all'Utente di realizzare, a mero titolo esemplificativo
                        e non limitativo, tra le altre, le seguenti attività:<br /><br />
                        <ul>
                          <li><p>Utilizzare strumenti, software o script associati all'utilizzo del Sito;</p></li>
                          <li><p>Bloccare, sovrascrivere, modificare o copiare, salvo nella misura necessaria per il corretto utilizzo dei servizi del Sito. Ad esempio, la copia o la riproduzione mediante tecnologie di ricerca di tipo "Robot/Crawler" non è necessaria ai fini del corretto utilizzo del Sito, motivo per cui tale pratica è espressamente vietata;</p></li>
                          <li><p>Diffondere e divulgare al pubblico senza previa autorizzazione contenuti del Sito o di altri Utenti;</p></li>
                          <li><p>Qualsiasi azione atta a pregiudicare la funzionalità dell'infrastruttura di fanta1.it, specialmente al fine di sovraccaricarla;</p></li>
                          <li><p>
                            Utilizzare qualsiasi materiale o informazione contenuti nel presente
                            Sito per finalità illecite e/o espressamente vietate dalle presenti
                            Condizioni generali di utilizzo, dalle Condizioni particolari eventualmente
                            applicabili, e/o che risultino contrari ai diritti e agli interessi di
                            fanta1.it, dei suoi membri e/o di terzi. L'Utente è tenuto a rispondere
                            nei confronti di eventuali parti lese per violazione o inadempimento di tali
                            obblighi, qualora danneggi, renda inutilizzabile, sovraccarichi, deteriori
                            o impedisca in qualsiasi modo (incluso mediante inserimento o diffusione di
                            virus informatici) il normale utilizzo dei materiali e delle informazioni
                            contenuti sul Sito, dei sistemi informatici o dei documenti, file e ogni
                            genere di contenuti archiviati su qualsiasi dispositivo informatico (hacking)
                            di fanta1.it, dei suoi membri o di altri Utenti;
                          </p></li>
                          <li><p>Pubblicare commenti o contenuti estranei all'oggetto e alle finalità del Sito;</p></li>
                          <li><p>Inserire dati di contatto in campi differenti da quelli appositamente destinati nei moduli. fanta1.it si riserva il diritto di modificare qualsiasi contenuto che contravvenga a quest’obbligo.</p></li>
                        </ul>
                        L'Utente prende atto e accetta che l'utilizzo del Sito e dei servizi
                        prestati su questo avviene, in ogni caso, unicamente ed esclusivamente
                        sotto la sua responsabilità.<br /><br />
                        L'Utente è tenuto a rispondere per danni e perdite di qualsiasi
                        natura eventualmente sofferti da fanta1.it o da terzi derivanti
                        dall'inadempimento di qualsiasi degli obblighi incombenti sull’Utente
                        in virtù delle presenti Condizioni Legali o delle normative vigenti
                        all’utilizzo del Sito e dei servizi offertivi.<br /><br />
                        fanta1.it non è obbligata a intervenire in caso di controversie
                        insorgenti tra i propri Utenti, o tra i propri Utenti e terzi,
                        suscettibili d'interferire nella corretta prestazione dei servizi,
                        riservandosi il diritto di eliminare i loro account sul Sito o di
                        risolvere qualsiasi contratto in essere, se del caso, fino alla completa
                        composizione di ogni controversia o vertenza.
                      </p>
                    </li>
                    <li>
                      <h4>Obblighi relativi alla pubblicazione di contenuti sul Sito</h4>
                      <p>
                        Tramite la pubblicazione di informazioni, testi, audio, video e/o
                        immagini sul Sito (in appresso, collettivamente, i "Contenuti"),
                        l'Utente dichiara di essere legittimo titolare dei diritti di proprietà
                        intellettuale e industriale su detti Contenuti per la loro riproduzione,
                        la distribuzione e la divulgazione al pubblico con qualsiasi mezzo
                        e su qualsiasi supporto, in tutto il mondo e per l’intera vigenza
                        della tutela legale degli stessi.<br /><br />
                        A tal fine, l'Utente dichiara di possedere il legittimo diritto
                        alla pubblicazione dei Contenuti sul Sito e di manlevare e tenere
                        indenne fanta1.it da qualsiasi reclamo o pretesa eventualmente
                        ricevuti a tale titolo. Gli Utenti sono gli unici responsabili
                        dell'ottenimento di ogni genere di autorizzazioni, permessi o
                        licenze eventualmente necessari per la creazione e pubblicazione
                        di tutti i Contenuti, incluse le autorizzazioni e/o i permessi
                        obbligatori in materia di diritti di immagine.<br /><br />
                        Al momento della pubblicazione dei Contenuti sul Sito,
                        l’Utente autorizza espressamente fanta1.it a utilizzare
                        e diffondere detti Contenuti su qualunque sito web amministrato
                        da fanta1.it o dei social network su cui sia presente.<br /><br />
                        Tutti i Contenuti pubblicati dagli Utenti devono intendersi come
                        formanti parte dell’opera di ingegno costituita dal Sito come
                        oggetto di tutela della disciplina dei diritti di proprietà
                        intellettuale, il cui unico legittimo titolare è fanta1.it.
                        A tal fine, l’Utente che realizzi qualsiasi apporto di Contenuti,
                        dichiara espressamente di cedere liberamente e gratuitamente a
                        favore di fanta1.it i Contenuti apportati e ogni diritto
                        di proprietà intellettuale sugli stessi e, in particolare, i
                        diritti di riproduzione, trasformazione e divulgazione al pubblico
                        in tutto il mondo e per l’intera vigenza della tutela legale
                        degli stessi.<br /><br />
                        fanta1.it si riserva il diritto di impedire la pubblicazione
                        di Contenuti suscettibili, a suo criterio, di pregiudicare
                        la qualità del servizio. È fatto divieto di pubblicare i contenuti:<br /><br />
                      </p>
                      <ul>
                        <li><p>Che risultino presumibilmente illeciti ai sensi delle normative nazionali, comunitarie o internazionali o suscettibili di commettere atti presumibilmente illeciti o comunque contrari ai principi di buona fede;</p></li>
                        <li><p>Che non soddisfino i parametri minimi di qualità stabiliti da fanta1.it per la loro pubblicazione sul Sito;</p></li>
                        <li><p>Suscettibili di violare i diritti fondamentali delle persone, di ferire la sensibilità degli Utenti, di contravvenire a norme di cortesia sul Web o comunque molesti e suscettibili di generare o indurre opinioni negative negli Utenti o terzi;</p></li>
                        <li><p>Che contravvengano ai principi di legalità, onorabilità, responsabilità, tutela della dignità umana, tutela dei minori, tutela dell'ordine pubblico, tutela della privacy, tutela del consumatore e dei diritti di proprietà intellettuale e industriale.</p></li>
                      </ul>
                      <p>
                        Salvo e impregiudicato quanto sopra, fanta1.it ricorda ai propri
                        Utenti che l’analisi dei succitati Contenuti deve condursi da un punto
                        di vista qualitativo e non legale, non essendo fanta1.it legittimata
                        né compente al perseguimento di reati penali o alla denuncia di illeciti civili.<br /><br />
                        fanta1.it si riserva il diritto di ritirare dal Sito i Contenuti
                        considerati non appropriati per le caratteristiche e le finalità del Sito
                        stesso e per gli Utenti. fanta1.it si riserva altresì il diritto di
                        modificare e/o adeguare i Contenuti nella misura del necessario, senza che
                        tali modifiche pregiudichino l'integrità dei Contenuti stessi, al fine di
                        adeguarli ai requisiti formali e di qualità del Sito o dei supporti sui
                        quali detti contenuti siano pubblicabili, ai sensi delle presenti
                        Condizioni Legali.<br /><br />
                        Nonostante fanta1.it adotti misure nell’intento di garantire
                        che i Contenuti non violino i divieti di cui sopra e soddisfino i
                        parametri di qualità di fanta1.it nonché le norme di cui alle
                        presenti Condizioni Legali, fanta1.it non è comunque tenuta a
                        controllare i Contenuti pubblicati, non essendo di conseguenza obbligata
                        ad assumersi alcuna responsabilità a tale titolo.<br /><br />
                        Qualora un Utente venga a conoscenza di Contenuti inappropriati
                        sul Sito, è pregato di contattare fanta1.it rivolgendosi
                        al nostro Servizio clienti all’indirizzo e-mail: info[chiocciola]fanta1.it<br /><br />
                        Per quanto riguarda i Contenuti potenzialmente pregiudizievoli
                        nei confronti di minori, fanta1.it manifesta la propria
                        volontà di favorire il corretto utilizzo di Internet.
                        fanta1.it manifesta, altresì, la propria volontà di
                        evitare la pratica e la diffusione di messaggi o immagini
                        di natura illecita o suscettibili di attentare o istigare ad
                        attentare alla dignità umana, alla sicurezza o ai valori di
                        tutela dell'infanzia e dell'adolescenza.<br /><br />
                        Il Sito riconosce, rispetta e tutela i diritti dei minori
                        nell'ambito della tutela dell'adolescenza e dell'infanzia
                        sia a livello nazionale che internazionale.<br /><br />
                        Ai sensi delle normative vigenti, si considera intromissione
                        illegittima nel diritto all’onore, alla tutela della vita
                        privata e familiare e all'immagine stessa del minore,
                        qualsiasi utilizzo della sua immagine o del suo nome sui
                        mezzi di comunicazione suscettibile di attentare alla sua
                        onorabilità o reputazione, o comunque contrario ai suoi interessi,
                        incluso con il consenso del minore o dei suoi rappresentanti
                        legali o tutori.<br /><br />
                        A tal fine, fanta1.it si riserva il diritto di
                        eliminare l’account di qualsiasi Utente che pubblichi
                        sul Sito qualsiasi Contenuto suscettibile di vulnerare
                        i diritti di cui al paragrafo anteriore, segnalandone
                        la relativa condotta alle pubbliche autorità competenti.
                      </p>
                    </li>
                    <li>
                      <h4>Responsabilità e diffusione di contenuti, dati e/o informazioni degli Utenti</h4>
                      <p>
                        fanta1.it ha facoltà di utilizzare e diffondere i
                        Contenuti pubblicati dagli Utenti sul Sito e, in particolare,
                        di procedere alla diffusione degli stessi su qualsiasi sito
                        web amministrato da fanta1.it o sui social network su
                        cui sia presente, al momento della pubblicazione di Contenuti
                        sul Sito, ragion per cui gli Utenti autorizzano espressamente
                        fanta1.it a utilizzare e diffondere tali Contenuti.<br /><br />
                        In virtù di quanto sopra, fanta1.it si riserva il
                        diritto di modificare o adeguare i Contenuti che così
                        lo richiedano al fine di rispettare la grafica del Sito
                        o di altri supporti di comunicazione impiegati sulla
                        piattaforma di fanta1.it, a condizione che tale
                        modifica o adeguamento rispetti l'integrità del Contenuto
                        pubblicato. I suddetti diritti o licenze d'uso si
                        intendono concessi per tutto il mondo e per l'intera
                        vigenza della tutela legale dei diritti dell'Utente.<br /><br />
                        fanta1.it non è tenuta a controllare l'utilizzo
                        dei servizi prestati effettuato dagli Utenti e, di
                        conseguenza, non è tenuta a garantire che l’utilizzo
                        da parte degli Utenti di detti servizi avvenga in conformità
                        alle presenti Condizioni generali di utilizzo, né che
                        facciano un uso diligente e/o prudente degli stessi,
                        malgrado il loro impegno in tal senso in virtù dell’utilizzo
                        del servizio e dell’accettazione delle presenti
                        Condizioni generali di utilizzo.<br /><br />
                        fanta1.it non è parimenti tenuta a verificare l'identità
                        degli Utenti, né la veridicità, la validità, la completezza
                        e/o l’autenticità dei dati personali forniti dagli Utenti,
                        assumendosi questi ultimi l’impegno di fornire a
                        fanta1.it dati veritieri, aggiornati e rispondenti
                        alle loro reali circostanze, ai sensi delle presenti
                        Condizioni generali di utilizzo di fanta1.it.<br /><br />
                        Salvo e impregiudicato quanto sopra, fanta1.it si
                        riserva il diritto di limitare, totalmente o parzialmente,
                        l'accesso ai servizi a determinati Utenti, nonché di
                        cancellare, sospendere, bloccare, modificare o eliminare
                        determinate tipologie di Contenuti, nonché di eliminare
                        l'account degli Utenti che facciano un uso indebito del
                        Sito o qualora venga a conoscenza della natura illecita
                        o lesiva di atti o di informazioni archiviate nei
                        confronti di eventuali beni o diritti di terzi, mediante
                        l'utilizzo di strumenti tecnologici atti a tale scopo.
                        A tal fine, fanta1.it ha facoltà di instaurare i
                        filtri necessari per evitare che, utilizzando il servizio,
                        si possano pubblicare su Internet contenuti illeciti o
                        nocivi o che il servizio sia utilizzato per finalità
                        contrarie a quelle previste.<br /><br />
                        Gli Utenti possono inoltrare proposte o suggerimenti
                        diretti al miglioramento delle varie informazioni,
                        attività e/o servizi contenuti e/o abilitati sul Sito,
                        rivolgendosi al Servizio clienti di fanta1.it.
                        Qualsiasi innovazione sviluppata in base ad eventuali
                        suggerimenti degli Utenti si intende di esclusiva
                        proprietà di fanta1.it, con rinuncia espressa
                        dell'Utente a far valere ogni diritto sulla stessa.<br /><br />
                        fanta1.it si riserva il diritto di registrare
                        qualsiasi tipo di comunicazione intervenuta tra gli
                        Utenti esclusivamente per finalità di analisi interna
                        e formative, salve e impregiudicate le disposizioni
                        contenute nella sua Politica sulla protezione dei dati
                        personali.
                      </p>
                    </li>
                    <li>
                      <h4>Esclusione di garanzie e responsabilità</h4>
                      <p>
                        Fatti salvi i casi espressamente stabiliti dalle
                        Condizioni generali di utilizzo e restanti condizioni
                        contenute nelle presenti Condizioni Legali, fanta1.it
                        non si assume alcuna responsabilità per danni e perdite di
                        qualsiasi natura eventualmente imputabili a inesattezza,
                        incompletezza o vetustà, nonché ad errori od omissioni
                        attinenti alle informazioni o ai servizi offerti sul
                        Sito o altri Contenuti ai quali sia possibile accedere
                        sul Sito, né si assume alcun obbligo o impegno di
                        verificare o controllare tali Contenuti e informazioni.<br /><br />
                        In aggiunta, pur impiegando tutti i mezzi tecnici a
                        propria disposizione, fanta1.it non è tenuta a
                        garantire la disponibilità, la continuità né l’infallibilità
                        del funzionamento del Sito e, di conseguenza, nei limiti
                        consentiti dalla legislazione vigente, non si assume alcuna
                        responsabilità per danni e perdite di qualsiasi natura
                        eventualmente imputabili alla mancata disponibilità o alla
                        discontinuità del funzionamento del Sito e dei servizi in
                        esso abilitati, nonché a errori di accesso a siti web
                        distinti o da cui tali servizi vengono prestati.<br /><br />
                        fanta1.it non si assume alcuna responsabilità per
                        i Contenuti, i dati e/o le informazioni forniti dagli
                        Utenti, neppure per i contenuti di siti web esterni verso
                        i quali esistano dei link di collegamento. In particolare,
                        fanta1.it non è tenuta a garantire che tali contenuti
                        siano veritieri, che siano funzionali rispetto a una
                        determinata finalità o possano servire a detta finalità.<br /><br />
                        Allo stesso modo, fanta1.it non si assume alcuna
                        responsabilità in merito alle opinioni pubblicate dagli
                        Utenti sul Sito, sui forum, sulle Chat o su altri
                        strumenti di partecipazione o di condivisione di
                        opinioni. Salvo e impregiudicato quanto sopra,
                        fanta1.it si impegna a utilizzare i mezzi a
                        propria disposizione al fine di moderare, nella misura
                        del possibile, le opinioni espresse dagli Utenti sul
                        Sito affinché non violino eventuali diritti di terzi.<br /><br />
                        Infine, fanta1.it non si assume alcuna responsabilità
                        in relazione all’esito di eventuali transazioni effettuate
                        tra Utenti.
                      </p>
                    </li>
                    <li>
                      <h4>Link a siti web di terzi</h4>
                      <p>
                        fanta1.it ha facoltà di inserire nei propri Contenuti
                        dei link a siti web appartenenti e/o amministrati da terzi
                        al fine di facilitare l'accesso alle informazioni
                        disponibili via Internet. Tali link possono essere
                        vincolati o meno ai summenzionati siti web.<br /><br />
                        fanta1.it non si assume alcuna responsabilità
                        derivante dall’inserimento di link tra i Contenuti
                        del Sito e i contenuti alloggiati all’esterno dello
                        stesso, o da qualsiasi altro riferimento a contenuti
                        esterni al presente Sito. Tali link o riferimenti
                        rispondono a finalità meramente informative e non
                        implicano in nessun caso l’esistenza di supporto,
                        approvazione, rapporto commerciale o di altro genere
                        tra fanta1.it e le persone fisiche o giuridiche
                        autrici e/o responsabili di tali contenuti o titolari
                        dei siti web su cui sono alloggiati.
                      </p>
                    </li>
                    <li>
                      <h4>Legislazione applicabile e foro competente</h4>
                      <p>
                        Le presenti Condizioni generali di utilizzo del Sito,
                        così come le restanti condizioni, sono disciplinate dal
                        diritto italiano.<br /><br />
                        Per eventuali controversie da risolversi o azioni
                        legali da esercitarsi derivanti dalla prestazione dei
                        servizi e dai Contenuti del Sito, nonché in merito
                        all'interpretazione, all’applicazione, all’adempimento
                        o inadempimento delle disposizioni contenute nelle
                        presenti Condizioni Legali, fanta1.it e l'Utente,
                        con rinuncia espressa a qualsiasi altro foro
                        eventualmente competente, convengono di sottomettersi
                        alla giurisdizione dei giudici e tribunali della città
                        di Milano.
                      </p>
                    </li>
                    <li>
                      <h4>Contatto</h4>
                      <p>
                        È possibile contattare fanta1.it all’indirizzo
                        e-mail o all’indirizzo postale pubblicati sopra.
                      </p>
                    </li>
                  </ol>
                  <li>
                    <h3>Servizi offerti</h3>
                    <p>
                      I servizi offerti di fanta1.it consistono nel dare la possibilità
                      agli Utenti una propria scuderia composta da una coppia di piloti di Formula 1.
                      fanta1.it ad ogni fine gara ed entro l'inizio della prossima, pubblicherà i
                      punteggi presi da ogni singolo pilota. Tutti i punteggi vengono assegnati
                      in base alle azioni effettuate durante la gara e in base alle immagini e audio trasmesse
                      da SkySport F1. Tutto ciò che emerge dopo il taglio del traguardo, non verrà preso
                      in considerazione.
                    </p>
                  </li>
                </li>
                <li>
                  <h3>Condizioni particolari della chat</h3>
                  <p>
                    L'utilizzo della Chat implica la piena accettazione da parte
                    degli Utenti delle Condizioni particolari della Chat e delle
                    restanti Condizioni generali di utilizzo del Sito contenute
                    nelle presenti Condizioni Legali.
                  </p>
                  <ol type="a">

                    <li>
                      <h4>Oggetto e descrizione della chat</h4>
                      <p>
                        L’oggetto delle presenti Condizioni particolari è la
                        prestazione della chat da parte del Sito. Gli Utenti,
                        gratuitamente, previa registrazione sul Sito, possono
                        scambiare, visualizzare e rispondere a messaggi e, in
                        generale, conversare con altri Utenti della Chat per
                        esprimere e/o richiedere opinioni e scambiare impressioni
                        sulle informazioni, sulle attività e/o sui servizi
                        contenuti nel Sito o resi accessibili tramite lo stesso,
                        nonché su tematiche similari.
                      </p>
                    </li>
                    <li>
                      <h4>Dati personali</h4>
                      <p>
                        fanta1.it informa i propri Utenti che il trattamento
                        dei dati personali viene condotto in conformità alla
                        Politica sulla protezione dei dati personali del Sito
                        disponibile per la consultazione accedendo al seguente
                        link:
                        <Link to="/policy-privacy">
                          https://fanta1.it/privacy
                        </Link><br /><br />
                        Gli Utenti sono tenuti a garantire la veridicità,
                        l’esattezza, la validità e l’autenticità dei Dati
                        Personali forniti, impegnandosi a mantenerli debitamente
                        aggiornati.
                      </p>
                    </li>
                    <li>
                      <h4>Reclami</h4>
                      <p>
                        fanta1.it mette a disposizione degli Utenti del
                        Sito appositi moduli per reclami, richiedibili
                        all’indirizzo e-mail info[chiocciola]fanta1.it.
                        Una volta compilati i moduli, l’Utente deve inviarne
                        una copia all’indirizzo e-mail di cui sopra.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <h3>Diritti di proprietà intellettuale e industriale</h3>
                  <p>
                    Copyright © 2024 FantaOne - Tutti i diritti riservati<br /><br />
                    Tutti i diritti di sfruttamento economico si intendono riservati.<br /><br />
                    Questo Sito è regolato dal diritto italiano ed è tutelato
                    dalla disciplina nazionale e internazionale dei diritti
                    sulla proprietà intellettuale e industriale.<br /><br />
                    I testi, disegni, immagini, database, loghi, struttura,
                    marchi e altri elementi del presente Sito sono tutelati
                    dalle leggi e dai trattati internazionali sulla proprietà
                    intellettuale e industriale.<br /><br />
                    È severamente vietata qualsiasi riproduzione, trasmissione,
                    adattamento, traduzione, modifica, divulgazione al pubblico
                    o altro utilizzo di tutto o parte dei Contenuti del presente
                    Sito, in qualsiasi forma o con qualsiasi mezzo, elettronico,
                    meccanico o di altro tipo, salvo previa autorizzazione
                    scritta di fanta1.it o di terzi eventualmente titolari
                    dei diritti in questione. Qualsiasi violazione dei suddetti
                    diritti può comportare l’avvio dei pertinenti procedimenti
                    stragiudiziali o giudiziali in sede civile o penale.<br /><br />
                    fanta1.it non concede alcuna licenza d’uso né
                    autorizzazione di alcun tipo sui diritti di proprietà
                    intellettuale e industriale né su altre proprietà o diritti
                    associati al Sito, né sui servizi o sui Contenuti dello stesso.<br /><br />
                    L'Utente dichiara espressamente che nessuno dei Contenuti
                    forniti a fanta1.it vulnera in alcun modo i diritti
                    di proprietà industriale o intellettuale o di altra natura
                    di titolarità di terzi, e di manlevare e tenere indenne
                    fanta1.it da eventuali reclami o pretese a tale titolo
                    da parte di terzi, assumendosi l’Utente la piena
                    responsabilità di detti Contenuti.<br /><br />
                    La legittimità della titolarità dei diritti di proprietà
                    intellettuale e industriale corrispondenti ai Contenuti
                    forniti dagli Utenti è di esclusiva responsabilità di questi
                    ultimi, dovendo espressamente impegnarsi a manlevare e tenere
                    indenne fanta1.it da ogni possibile reclamo e pretesa
                    da parte di terzi.<br /><br />
                    Ai fini di tutelare eventuali diritti di proprietà
                    intellettuale e industriale, nel caso in cui un Utente
                    o un terzo estraneo ritenga si sia prodotta una violazione
                    dei propri diritti derivante dalla pubblicazione di
                    determinati Contenuti sul Sito, l’interessato è tenuto
                    a notificare tale evento per iscritto all'indirizzo e-mail
                    info[chiocciola]fanta1.it, indicando:
                  </p>
                  <ul style={ { listStyleType: 'square' } }>
                    <li>
                      <p>
                        I dati identificativi dell'interessato, titolare dei diritti
                        presuntamente violati; gli estremi dei poteri di rappresentanza
                        in base ai quali agisce in caso di reclami presentati per conto
                        di terzi distinti dall’interessato;
                      </p>
                    </li>
                    <li>
                      <p>
                        I contenuti tutelati dai diritti di proprietà intellettuale
                        e relativa ubicazione sul Sito;
                      </p>
                    </li>
                    <li>
                      <p>
                        La prova dell'esistenza, della titolarità e della vigenza
                        dei diritti di proprietà intellettuale in questione;
                      </p>
                    </li>
                    <li>
                      <p>
                        Una dichiarazione di assunzione di piena responsabilità
                        dell'interessato sulla veridicità dei dati e delle informazioni
                        fornite nella notifica di cui al presente punto.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Protezione dei dati personali</h3>
                  <p>
                    Per la corretta prestazione dei servizi, fanta1.it si
                    impegna a trattate i dati personali degli Utenti.<br /><br />
                    A tale scopo, gli Utenti, sono
                    tenuti a compilare un modulo e a fornire i dati personali
                    richiesti da fanta1.it e indicati come indispensabili
                    per la corretta prestazione dei servizi.
                  </p>
                </li>
                <li>
                  <h3>Politica di non discriminazione</h3>
                  <p>
                    L’intenzione di fanta1.it è che tuttI gli Utenti
                    si sentano accettati e inclusi in tutti i
                    propri portali, applicazioni e strumenti (di seguito,
                    congiuntamente, le “Proprietà”).<br /><br />
                    Per questo motivo, fanta1.it proibisce ogni condotta
                    discriminatoria nei confronti di Utenti o dipendenti di
                    fanta1.it sulla base della loro razza, colore, religione,
                    sesso, nazionalità, origine, incapacità, stato civile,
                    situazione familiare, stato di gravidanza, orientamento
                    sessuale, identità di genere, espressione di genere,
                    cittadinanza, età, o altra caratteristica protetta dalle
                    normative vigenti. A mero titolo informativo e non limitativo,
                    si considera condotta discriminatoria il rifiuto di prestare
                    o di accettare servizi o qualsiasi altra condotta indebita e
                    basata sulle caratteristiche di cui sopra.<br /><br />
                    Come stabilito nelle presenti Condizioni Legali, il divieto
                    in questione si estende anche alla pubblicazione di contenuti
                    discriminatori, come opinioni o pubblicazioni su forum o su
                    qualunque delle nostre Proprietà.<br /><br />
                    fanta1.it si riserva il diritto di adottare tutte le
                    misura ritenute necessarie, a sua discrezione, per rafforzare
                    l’osservanza di detta Politica, con facoltà incluso di
                    eliminare gli account sulle Proprietà degli utenti e/o i
                    professionisti che non vi adempiano.<br /><br />
                    Qualora si venga conoscenza di alcun tipo di condotta
                    discriminatoria da parte di un Utente, si prega di notificarlo
                    all’indirizzo e-mail info[chiocciola]fanta1.it,
                    menzionando nell’oggetto “Politica di non discriminazione”
                    onde permetterci di prendere gli opportuni provvedimenti.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default CondizioniLegali;
