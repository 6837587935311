import React, { useEffect } from 'react';
import '../../scss/ModalCreateTeam.scss';

function ModalSocial() {

  const [myModal, setMyModal] = React.useState({
                                  display: 'none',
                                  ctrl: 0
                                });

  useEffect(() => {

    // let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    // if( userInfo.level == 78 && myModal.ctrl == 0 ) {
    //   setMyModal({ display: "block" });
    // }


  });

  function setClick(e) {

  }

  return (
    <div className="modal" style={{ display: myModal.display }}>
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="modal-header">
          Aiutaci a crescere!
          <div className="closeModal" onClick={(e)=> {setMyModal({ display: "none", ctrl: 1 })}}>
            X
          </div>
        </div>
        <div className="modal-body">
          Ciao!<br />
          Siamo un gruppo di giovani appassionati di Formula 1 proprio come te! Siamo di varie etnie, sesso e religione e 
          tutto questo lo facciamo solo per PASSIONE e DIVERTIMENTO. 
          La LEGGEREZZA è il nostro mantra!<br /><br />
          Nella prima settimana del campionato eravamo più di 400 iscritti. Non sappiamo dove arriveremo ma qualunque sia 
          il nostro traguardo, lo vivremo insieme! <br /><br />
          Quindi aiutaci a crescere insieme mettendo like alla nostra pagina e condividendo il nostro FantaOne!
          <br /><br />
          <div className="content-button">
            <a href="https://www.facebook.com/profile.php?id=100090841984906" target="_blank"  rel="noreferrer">
              <button type="button" className="button" onClick={(e)=> {setClick(e)}}>
                Facebook FantaOne
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSocial;
