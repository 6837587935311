import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import '../../scss/ModalCreateTeam.scss';

function ModalCreateLega(props) {

  const { t } = useTranslation('common');

  const [myModal, setMyModal] = React.useState('none');

  const [state, setState] = React.useState({myLega: "", myLegaPublic: false, myFriend: "", id: 0});

  const [stateErrorMyLega, setStateErrorMyLega] = React.useState(false);

  // const [addUser, setStateAddUser] = React.useState(false);

  // const [myCtrlModalLega, setMyCtrlModalLega] = React.useState(true);

  useEffect(() => {
    setMyModal(props.open);
    if( props.open == 'block' ) {
      window.scrollTo(0, 0);
      document.getElementsByClassName("check")[0].checked = false;
      if( props.edit ) {
        if( props.edit.hasOwnProperty('id') ) {
          setState({...state, 
            myLega: props.edit.name, 
            myLegaPublic: props.edit.isPublic == 1 ? true : false, 
            id: props.edit.id});
            if( props.edit.isPublic == 1 ) {
              document.getElementsByClassName("check")[0].checked = true;
            }
        }
        else {
          setState({...state, 
            myLega: "", 
            myLegaPublic: false,
            id: 0});
        }
      }
    }
  }, [props]);

  function sendMyLega(e) {
    e.preventDefault();

    setStateErrorMyLega(false);

    if( state.myLega ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      let params = {
        token: localStorage.getItem("fm_session"),
        id: userInfo.id,
        myLega: state.myLega,
        myLegaPublic: state.myLegaPublic,
        lega_id: state.id
      }
      axios.post(process.env.REACT_APP_API_URL + '/create-lega/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setMyModal('none');
              setState({myLega: "", myLegaPublic: false, myFriend: "", id: 0});
              setTimeout(() => {
                props.createMyLega(null, 'none');

              }, 500)
            }
            else {
              // NO
              
            }
          });
    }
    else {
      if( !state.myLega ) {
        setStateErrorMyLega(true);
      }
    }
  }

  return (
    <div className="modal" style={{ display: myModal }}>
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="modal-header">
          Crea la tua Lega
          <div className="closeModal" onClick={(e)=> {props.createMyLega(e, 'none')}}>
            X
          </div>
        </div>
        <div className="modal-body">
          <div className="marginCenter">
            <div className="content-input">
              <label htmlFor="myTeam">Inserisci il nome della tua Lega</label>
              <input
                  type="text"
                  className="input"
                  name="myTeam"
                  onChange={(ev) => setState({...state, myLega: ev.target.value})} 
                  value={state.myLega}
                  required
                  style={!stateErrorMyLega ? { marginBottom: '30px' } : { marginBottom: 0 } } />
                <p className="error" style={!stateErrorMyLega ? { display: 'none' } : { display: 'block' } }><small>Il nome è un campo obbligatorio</small><br /><br /></p>
            </div>
            <div className="content-input">
              <label htmlFor="myTeamPublic">Vuoi che sia pubblica e chiunque abbia accesso a FantaOne possa aggiungersi?</label>
              <br />
              <input type="checkbox" 
                      name="isPublic" 
                      className="check" 
                      defaultChecked={state.myLegaPublic}
                      onChange={(ev) => setState({...state, myLegaPublic: ev.target.checked})} /> <span>Sì</span>
            </div>
            <div className="content-button">
              <button type="button" className="button" onClick={(e)=> {sendMyLega(e)}}>
                Salva
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCreateLega;
