import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Error from './partials/Error';
import ModalCreateTeam from './partials/ModalCreateTeam';
import ModalSocial from './partials/ModalSocial';
import ModalCreateLega from './partials/ModalCreateLega';
import ModalLega from './partials/ModalLega';
import { useTranslation } from "react-i18next";
import '../scss/Dashboard.scss';
import AdSense from 'react-adsense';

function Dashboard() {

  let history = useHistory();

  const { t, i18n } = useTranslation('common');

  const [myInfo, setMyInfo] = React.useState([]);

  const [myTeamState, setMyTeamState] = React.useState([]);

  const [allTeamState, setAllTeamState] = React.useState([]);

  const [allRacesState, setAllRacesState] = React.useState([]);

  const [allDriversState, setAllDriversState] = React.useState([]);

  const [allActionsState, setAllActionsState] = React.useState([]);

  const [yearState, setYearState] = React.useState(2022);

  const [bonus, setBonus] = React.useState(0);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myModalCreateTeam, setMyModalCreateTeam] = React.useState('none');

  const [myModalCreateLega, setMyModalCreateLega] = React.useState('none');

  const [appError, setStateError] = React.useState("");

  const [tabLega, setStateTabAcca] = React.useState({ one: true, two: false});

  const [allLeaugueState, setAllLeaugueState] = React.useState([]);

  const [allLeauguePublicState, setAllLeauguePublicState] = React.useState([]);

  const [editMyLega, setEditMyLegaState] = React.useState(false);

  const [myModalLega, setMyModalLega] = React.useState('none');

  const [viewLega, setViewLega] = React.useState([]);

  const [isMyLega, setIsMyLega] = React.useState(false);

  const [viewAllRaces, setViewAllRaces] = React.useState(false);

  const [myPos, setMyPos] = React.useState({
                              general: 0
                            });

  const [isPublic, setIsPublic] = React.useState(0);

  const [myStop, setMyStop] =  React.useState(true);

  useEffect(() => {

    window.scrollTo(0, 0);

    if( !localStorage.getItem("fm_info") ) {
      window.location.href = "/";
    }

    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        i18n.changeLanguage('it-IT');
      }
      else {
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    setMyInfo(userInfo);

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setMyTeamState(response.data.rls.drivers);
              // setBonus(response.data.rls.bonus);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-teams', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              let ctrlAllTeams = response.data.rls.allTeams;
              var byPoints = ctrlAllTeams.slice(0);
              byPoints.sort(function(a,b) {
                  var x = a.points;
                  var y = b.points;
                  return y < x ? -1 : y > x ? 1 : 0;
              });

              setAllTeamState(byPoints);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-points', params )
      .then(response => {
        if(response.data.error.status === false) {
          // OK
          setAllRacesState(response.data.rls.races);
          setAllDriversState(response.data.rls.drivers);
          setAllActionsState(response.data.rls.actions);
          setYearState(response.data.rls.year);
        }
        else {
          // NO
          onChangeMyError(response.data.error.rls);
        }
        return;
      });

      loadMyLeague(params);

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league-public', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setAllLeauguePublicState(response.data.rls);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });
    }

    setTabs(2);
    setTabs(1);

    setMyCtrl(false);

  }, [myCtrl]);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  function createMyTeam(e, ctrl) {
    setMyModalCreateTeam(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    }
  };

  function createMyLega(e, ctrl) {
    setEditMyLegaState([]);
    setMyModalCreateLega(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");

      let params = {
        token: localStorage.getItem("fm_session"),
        id: myInfo.id
      }

      loadMyLeague(params);

      axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league-public', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setAllLeauguePublicState(response.data.rls);
            }
            else {
              // NO
              onChangeMyError(response.data.error.rls);
            }
            return;
          });
    }
  };

  function ctrlPoint( race_id ) {
    allDriversState.map(function(driver, i) {
      let sumPoint = 0;
      allActionsState.map(function(action, j) {
          let userInfo = JSON.parse(localStorage.getItem("fm_info"));
          let params = {
            token: localStorage.getItem("fm_session"),
            id: userInfo.id,
            race_id: race_id,
            driver_id: driver.id,
            action_id: action.id
          }
          axios.post(process.env.REACT_APP_API_URL + '/dashboard/single-point', params )
            .then(response => {
              if(response.data.error.status === false) {
                // OK
                if( response.data.rls.point ) {
                  document.getElementById('pointSingleRace-' + race_id + '-' + driver.id + '-' + action.id).classList.add("active");
                  sumPoint = sumPoint + parseInt(action.point);
                  document.getElementById('pointSingleRace-' + race_id + '-' + driver.id + '-0').innerHTML = sumPoint + 'pt';
                }
              }
              else {
                // NO
                onChangeMyError(response.data.error.rls);
              }
              return;
            });
      });
    });
    return 0;
  };

  // function tablePoint(i, item) {
  //     document.getElementById("bodySingleRace-" + i).innerHTML = allDriversState && allDriversState.length ? (
  //         allDriversState.map(function(driver, k) {
  //           return (
  //             <div className="rowSingleRace" key={ k }>
  //               <div className="driverSingleRace"><strong>{driver.name} {driver.surname}</strong></div>
  //               {allActionsState && allActionsState.length ? (
  //                 allActionsState.map(function(action, z) {
  //                   return (
  //                     <div
  //                       id={"pointSingleRace-" + i + "-" + k + "-" + z}
  //                       className="pointSingleRace"
  //                       onLoad={ ctrlPoint( item.id, driver.id, action.id, i + "-" + k + "-" + z ) }
  //                       key={ z }>{action.point}</div>
  //                   );
  //                 })) : (<div>{t('dashboard.noResult')}</div>)}
  //             </div>
  //           );
  //         })) : (<div>{t('dashboard.noResult')}</div>);
  // }

  function setTabs( index ) {
    if( (index == 1 && !tabLega.one) || (index == 2 && !tabLega.two) ) {
      setStateTabAcca({
        one: !tabLega.one,
        two: !tabLega.two
      });
    }
  }

  function editLega( league ) {
    setEditMyLegaState(league);
    setMyModalCreateLega('block');
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }

  function deleteLega( league ) {
    if( window.confirm("Vuoi eliminare la Lega " + league.name + "?") ) {
      let params = {
        token: localStorage.getItem("fm_session"),
        id: myInfo.id,
        league_id: league.id
      }
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/delete-league', params )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            params = {
              token: localStorage.getItem("fm_session"),
              id: myInfo.id
            }
            loadMyLeague( params );
          }
          else {
            // NO
            onChangeMyError(response.data.error.rls);
          }
          return;
        });
    }
  }

  function loadMyLeague( params ) {
    axios.post(process.env.REACT_APP_API_URL + '/dashboard/all-league', params )
    .then(response => {
      if(response.data.error.status === false) {
        // OK
        setAllLeaugueState(response.data.rls);
      }
      else {
        // NO
        onChangeMyError(response.data.error.rls);
      }
      return;
    });
  }

  function viewModalLega( league, ctrl, isPublic = 0 ) {
    setViewLega(league);
    setIsMyLega(ctrl);
    setIsPublic(isPublic);
    setMyModalLega('block');
    document.getElementsByTagName("body")[0].classList.add("overflow");
  }

  function closeViewModalLega(e, ctrl) {
    setViewLega([]);
    setMyModalLega(ctrl);
    if( ctrl == 'block' ) {
      document.getElementsByTagName("body")[0].classList.add("overflow");
    }
    else {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    }
  }

  return (
    <div className="App">
      <Header />
      <div>
        DASHBOARD
      </div>
      <div className="content-card">
        <div className="card card-2">
          <div className="card-header">
            {t('dashboard.cardOne')} - { myInfo.nickname }
          </div>
          <div className="card-body">
            {myTeamState && myTeamState.length ? (
              myTeamState.map(function(item, i) {
                return (
                  <div className="contentValue contentMyTeam" key={ i }>
                    <div><img src={ item.link } width="50" style={{color: item.color}} /></div>
                    <div>{ item.driver_name }</div>
                    <div><span className="viewPoins">{ item.points }pt</span></div>
                  </div>
                );
               })
            ) : (
              <div>
                {t('dashboard.noResult')}
                <br /><br />
                <div className="content-button">
                  <button type="button" className="button" onClick={(e)=> {createMyTeam(e, 'block')}}>
                    {t('dashboard.createTeam')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card card-2">
          <div className="card-header">
            {t('dashboard.cardTwo')} - <span className="myPos">Sei in P.{ myPos.general }</span>
          </div>
          <div className="card-body allUsers">
            {allTeamState && allTeamState.length ? (
              allTeamState.map(function(item, i) {
                if( item.nickname == myInfo.nickname && myPos.general == 0 ) {
                  setMyPos({...myPos, general: i + 1});
                }
                return (
                  <div className={item.nickname == myInfo.nickname ? "contentValue iam" : "contentValue"} key={ i }>
                    <div className="contentAllTeam">
                      <div><span className="position">#{ i + 1 }</span></div>
                      <div>{ item.nickname }</div>
                      <div><span className="viewPoins">{ item.points }pt</span></div>
                    </div>
                      
                    <br />
                    <small><i>{ item.drivers.replace('-', ' - ') }</i></small>
                  </div>
                );
               })
            ) : (
              <div>{t('dashboard.noResultAll')}</div>
            )}
          </div>
        </div>
      </div>
      <div className="singleBox">
         {/* LEGA */}
        <div className="singleBox">
          <div className="card">
            <div className="card-header">
              {t('dashboard.league')}
            </div>
            <div className="card-body">
              {t('dashboard.leagueSubtitle')}
              <div className="contentTabHeader">
                <div className={"tab " + (tabLega.one ? "active " : "")}  onClick={(e)=> {setTabs(1)}}>
                  {t('dashboard.leagueTabOne')}
                </div>
                <div className={"tab " + (tabLega.two ? "active " : "")}  onClick={(e)=> {setTabs(2)}}>
                  {t('dashboard.leagueTabTwo')}
                </div>
                <div className="contentTabBody">
                  <div className={"tabBody " + (tabLega.one ? "active " : "")}>
                  {allLeaugueState && allLeaugueState.length ? (
                            <div>
                              <div className="content-button buttonRight">
                                <button type="button" className="button" onClick={(e)=> {createMyLega(e, 'block')}}>
                                  {t('dashboard.leagueCreate')}
                                </button>
                              </div>
                              {allLeaugueState.map(function(leaugue, p) {
                                return (
                                  <div className="singleElm" key={ p }>
                                    <div className='first'><p>{ leaugue.name }</p><p className="MyCreate"><small><i>{ leaugue.user_id == myInfo.id ? 'Creata da te' : '' }</i></small></p></div> 
                                    <div><span className='fi fi-rr-eye' onClick={(e)=> {viewModalLega(leaugue, leaugue.user_id == myInfo.id)}}></span></div>
                                    <div style={leaugue.user_id == myInfo.id  ? { display: 'block' } : { display: 'none' } }><span className='fi fi-rr-pencil' onClick={(e)=> {editLega(leaugue)}}></span></div>
                                    <div style={leaugue.user_id == myInfo.id  ? { display: 'block' } : { display: 'none' } }><span className="fi fi-rr-trash" onClick={(e)=> {deleteLega(leaugue)}}></span></div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (<div>
                                  <p><i>{t('dashboard.leagueNotFound')}</i></p>
                                  <div className="content-button">
                                    <button type="button" className="button" onClick={(e)=> {createMyLega(e, 'block')}}>
                                      {t('dashboard.leagueCreateFirst')}
                                    </button>
                                  </div>
                                </div>)}
                  </div>
                  <div className={"tabBody " + (tabLega.two ? "active " : "")}>
                  {allLeauguePublicState && allLeauguePublicState.length ? (
                            <div>
                              {allLeauguePublicState.map(function(leaugue, p) {
                                return (
                                  <div className="singleElm" key={ p }>
                                    <div className='first'>{ leaugue.name }</div> 
                                    <div><span className='fi fi-rr-eye' onClick={(e)=> {viewModalLega(leaugue, false, 1)}}></span></div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (<div>
                                  <p><i>{t('dashboard.leagueNotFoundPublic')}</i></p>
                                </div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sponsor">
          <AdSense.Google
            client='ca-pub-7938158814942247'
            slot='7906899238'
            style={{ display: 'block', textAlign: 'center' }}
            format='fluid'
            responsive='true'
            layout='in-article'
          />
        </div>
        
        {/* TOTALE PUNTI PER PILOTA */}
        <div className="singleBox">
          <div className="card">
            <div className="card-header">
              {t('dashboard.cardFour')}
            </div>
            {myTeamState && myTeamState.length > 0 ? (
              <div className="card-body allPoints">
                <div className="contentDriverTable">
                  <div className="firstCol">
                    <div className="rowSingleRace">
                      <div className="rowSingleRaceOne">#</div>
                      <div className="rowSingleRaceTwo"></div>
                      <div className="rowSingleRaceThree">{t('dashboard.piloti')}</div>
                      <div className="rowSingleRaceFour">{t('dashboard.punti')}</div>
                    </div>
                    {allDriversState && allDriversState.length ? (
                      allDriversState.map(function(driver, k) {
                        if( k < 10) {
                          return (
                            <div className="rowSingleRace" key={ k }>
                              <div className="rowSingleRaceOne">{ k+1 }</div>
                              <div className="rowSingleRaceTwo"><img src={ driver.link } width="50" style={{color: driver.color}} /></div>
                              <div className="rowSingleRaceThree driverSingleRace"><strong>{driver.name} {driver.surname}</strong></div>
                              <div className="rowSingleRaceFour points">{driver.points ? driver.points : 0}pt</div>
                            </div>
                          );
                        }
                      })) : (<div>{t('dashboard.noResult')}</div>)}
                  </div>
                  <div className="secondCol">
                    <div className="rowSingleRace hideIsMobile">
                      <div>#</div>
                      <div></div>
                      <div>{t('dashboard.piloti')}</div>
                      <div>{t('dashboard.punti')}</div>
                    </div>
                    {allDriversState && allDriversState.length ? (
                      allDriversState.map(function(driver, k) {
                        if( k > 9) {
                          return (
                            <div className="rowSingleRace" key={ k }>
                              <div className="rowSingleRaceOne">{ k+1 }</div>
                              <div className="rowSingleRaceTwo"><img src={ driver.link } width="50" style={{color: driver.color}} /></div>
                              <div className="rowSingleRaceThree driverSingleRace"><strong>{driver.name} {driver.surname}</strong></div>
                              <div className="rowSingleRaceFour points">{driver.points ? driver.points : 0}pt</div>
                            </div>
                          );
                        }
                      })) : (<div>{t('dashboard.noResult')}</div>)}
                  </div>
                </div>
              </div>
            ) : (<div className="noSetTeam">{t('dashboard.noSetTeam')}</div>)}
          </div>
        </div>
        {/* FINE - TOTALE PUNTI PER PILOTA */}

        { /* PUNTI GARA PER GARA */}
        <div className="card card-1 marginBottom20">
          <div className="card-header">
            {t('dashboard.cardThree')} <span className='fi fi-rr-angle-down icon' onClick={(e)=> {setViewAllRaces(!viewAllRaces)}}></span>
          </div>
          <div className="card-body allPoints" style={!viewAllRaces ? { display: 'none' } : { display: 'block' } }>
            {allRacesState && allRacesState.length ? (
              allRacesState.map(function(item, i) {
                return (
                  <div className="" key={ i }>
                    <div
                      id={"headers-tab-" + i}
                      className={"headers-tab"}
                      onClick={() => {
                          document.getElementById('headers-tab-' + i).classList.toggle("active");
                          document.getElementById('contents-tab-' + i).classList.toggle("active");
                          if( document.getElementById('headers-tab-' + i).classList.contains("active") ) {
                            ctrlPoint( item.id );
                          }
                        }}>
                          <div><img src={ item.flag } width="50" /></div>
                          <div>&nbsp;&nbsp;{ item.race } { yearState }</div>
                          <div><span className='fi fi-rr-angle-down icon'></span></div>
                    </div>
                    <div id={"contents-tab-" + i} className="contents-tab">
                      {allDriversState.map(function(driver, j) {
                          return (
                            <div key={ j } className="contentSingleDriverTable">
                              <div className="contentDriverTable displayFlex">
                                <div className="contentDriverTableImg">
                                  <div className="imgCenter">
                                    <img src={ driver.link } width="50" style={{color: driver.color}} />
                                  </div>
                                </div>
                                <div>{driver.name} {driver.surname}</div>
                                <div className="contentDriverTablePoints" id={"pointSingleRace-" + item.id + '-' + driver.id + '-0'}>0pt</div>
                              </div>
                              <div>
                                <hr className="borderRed" />
                              </div>
                              <div>
                              {allActionsState.map(function(action, k) {
                                return (
                                  <div key={ k } className="rowAction" id={"pointSingleRace-" + item.id + '-' + driver.id + '-' + action.id}>
                                    <div className="contentDriverTable displayFlex">
                                      <div>{t('actions.' + action.what.replaceAll("&egrave;", "e").replaceAll(" ", "").replaceAll(":", ""))}</div>
                                      <div className="pointSingleRace">{action.point}pt</div>
                                    </div>
                                    <hr />
                                  </div>
                                )})}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
               })
            ) : (
              <div>{t('dashboard.noResult')}</div>
            )}
          </div>
        </div>
        {/* FINE - PUNTI GARA PER GARA */}
      </div>
      <Error params={appError} onChangeError={onChangeMyError} />
      <Footer />
      <ModalCreateTeam open={myModalCreateTeam} createMyTeam={createMyTeam} />
      <ModalSocial />
      <ModalCreateLega open={myModalCreateLega} createMyLega={createMyLega} edit={editMyLega} />
      <ModalLega open={myModalLega} close={closeViewModalLega} lega={viewLega} isMyLega={isMyLega} isPublic={isPublic} />
    </div>
  );
}

export default Dashboard;
