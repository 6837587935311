import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from './partials/Header';
import Footer from './partials/Footer';
import Cta from './partials/Cta';
import { useTranslation } from "react-i18next";
import '../scss/Home.scss';
import jsonData from '../point.json';
import AdSense from 'react-adsense';

function Home() {

  const { t, i18n } = useTranslation('common');

  const [points, setPoints] =  React.useState(false);

  const [myStop, setMyStop] =  React.useState(true);

  useEffect(() => {
    // setPoints(jsonData);
    if( myStop ) {
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        setPoints(jsonData.it);
        i18n.changeLanguage('it-IT');
      }
      else {
        setPoints(jsonData.en);
        i18n.changeLanguage('en-US');
      }
      setMyStop(false);
    }

  });

  function goToSection( scroll = 0 ) {
    let intViewportHeight = parseInt(scroll) + window.innerHeight + parseInt(15);
    window.scrollTo(scroll, intViewportHeight);
  }

  function handleShow(e, r) {
    e.preventDefault();
    let id = document.getElementById("valuePointDet-" + r);
    if( id.classList.contains('active') ) {
      id.classList.remove("active");
    }
    else {
      id.classList.add("active");
    }
  }

  // function handleHide(e) {
  //   e.preventDefault();
  //   setShow(false);
  // }

  return (
    <div id="home" className="App">
      <Header />
      <div>
        <div className="contentHome contentHomeOne">
          <div className="contentLogo">
            <img src="img/FantaUno-200.png" width="200" />
          </div>
          <div className="textFirstBlock">
            <h1 className="nameSite">Fanta 1</h1>
            <p>
              {t('home.textOne')}
            </p>
          </div>
          <div className="content-scroll">
            <div className="mouse" onClick={ (e) => goToSection() }>
              <div className="scroll"></div>
            </div>
            <div className="help-scroll"></div>
          </div>
        </div>
        <div className="contentHome">
          <div className="contentRow">
            <div className="col50">
              <div className="contentBlockOne">
                <img src="img/coppia.png" className="coppia" />
              </div>
            </div>
            <div className="col50 backGroundcolorPrimay">
              <p className="text" dangerouslySetInnerHTML={{__html: t('home.textTwo')}} />
              <div className="contentArrow" onClick={ (e) => goToSection(window.scrollY) }>
                <svg id="moreArrows">
                  <polygon className="arrowWhite arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                  <polygon className="arrowWhite arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                  <polygon className="arrowWhite arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>
              </div>
            </div>
          </div>

          <div className="contentHome">
            <div className="contentRow">
              <div className="col100 noHeight">
                <div className="contentSectionTwo">
                  <p>
                    {t('home.textThree')}
                  </p>
                  <br /><br />
                  <div className="nPoints">
                  {points && points.length > 0 ? (
                    points.map(function(item, r) {
                        return (
                          <div key={r} className="contentPrice" onClick={ (e) => handleShow(e, r) }>
                            <div className={ item.disabled ? "valuePoint disabled" : "valuePoint"}><span className="yellow">{ item.point == 0 ? "" : item.point }</span> { item.text } <span className="myArrow">›</span></div>
                            <div id={'valuePointDet-' + r} className="valuePointDet">
                              <br /><br />
                              { item.description }
                            </div>
                          </div>
                        );
                     })
                  ) : (
                    <div></div>
                  )}
                  </div>
                  <br /><br /><br />
                </div>
                <div className="sponsor">
                  <AdSense.Google
                    client='ca-pub-7938158814942247'
                    slot='7906899238'
                    style={{ display: 'block', textAlign: 'center' }}
                    format='fluid'
                    responsive='true'
                    layout='in-article'
                  />
                </div>
                <div className="contentTextProvvisorio">
                  <h2>{t('home.title')}</h2>
                  <p>{t('home.description')} </p>
                </div>
                <br /><br />
              </div>
            </div>
            <Cta />
            <Footer />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Home;
