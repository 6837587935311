import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../../scss/Header.scss';

function Header() {

  const [myLevel, setMyLevel] = React.useState(2);

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [myCookie, setMyCookie] = React.useState(true);

  const [myMenu, setMyMenu] = React.useState(false);

  const [myColorNav] = React.useState(false);

  useEffect(() => {

    if( localStorage.getItem('fm_cookie') && localStorage.getItem('fm_cookie') == 1 ) {
      setMyCookie(false);
    }

    if( localStorage.getItem("fm_info") ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      if( myCtrl ) {
        setMyLevel(userInfo.level);
      }

      setMyCtrl(false);
    }

  }, [myCtrl]);

  function logout(e) {
    e.preventDefault();
    localStorage.removeItem('fm_info');
    localStorage.removeItem('fm_session');
    window.location.href = "/";
  }

  function handleClose(e) {
    e.preventDefault();
    localStorage.setItem("fm_cookie", 1);
    setMyCookie(false);
  }

  function openMenu() {
    setMyMenu(!myMenu);
  }

  return (
    <div className="App">
      <div className="bannerCookie" style={myCookie ? { display: 'block' } : { display: 'none' } }>
        <p>
          I cookie ci permettono offrire i nostri servizi. Nell'utilizzare i
          nostri servizi, accetti l'uso che facciamo dei cookie.&nbsp;
          <Link to="/cookies" className="legalLink" target="_blank"  rel="noreferrer">
            Ulteriori informazioni
          </Link>&nbsp;&nbsp;&nbsp;
          <span className="closeButton" onClick={(e)=> {handleClose(e)}}>&times;</span>
        </p>
      </div>
      <header className="AppHeader">
        <div className="logo">
          <Link to="/">
            <img src="img/FantaUno-40.png" width="40" />
          </Link>
        </div>
        {localStorage.getItem('fm_session') ? (
          <div className="itemsMenu">
            <div>
              <Link to="/">
                  Home
              </Link>
              <span>|</span>
            </div>
            <div>
              <Link to="/dashboard">
                  Dashboard
              </Link>
              <span>|</span>
            </div>

            {localStorage.getItem('fm_session') && myLevel == 78 ? (
              <div>
                <div>
                  <Link to="/backoffice">
                      Backoffice
                  </Link>
                  <span>|</span>
                </div>
              </div>
            ) : (<div></div>)}

            <div className="hover last" onClick={(e)=> {logout(e)}}>
                Logout
            </div>
          </div>
        ) : (
          <div className="last itemsMenu">
            <Link to="/login">
                Login
            </Link>
          </div>
        )}
        {localStorage.getItem('fm_session') ? (
          <div className="itemsMenuMobile">
          <div className="content-nav-brand">
            <span id="nav-bar" className={myMenu ? 'change navbar-toggler start' : 'navbar-toggler start'} onClick={openMenu}>
              <div className="bar1" style={ myColorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : 'rgba(255, 255, 255, 1)' } }></div>
              <div className="bar2" style={ myColorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : 'rgba(255, 255, 255, 1)' } }></div>
              <div className="bar3" style={ myColorNav ? { backgroundColor : 'rgba(167, 57, 114, 1)' } : { backgroundColor : 'rgba(255, 255, 255, 1)' } }></div>
            </span>
          </div>
          <div className="listItemMobile" style={ myMenu ? { display: 'block' } : { display: 'none' } }>
            <hr />
            <div>
              <Link to="/">
                  Home
              </Link>
              <div className="sepa"><hr /></div>
              <Link to="/dashboard">
                  Dashboard
              </Link>
              <div className="sepa"><hr /></div>
              {localStorage.getItem('fm_session') && myLevel == 78 ? (
                <div>
                  <div>
                    <Link to="/backoffice">
                        Backoffice
                    </Link>
                    <div className="sepa"><hr /></div>
                  </div>
                </div>
              ) : (<div></div>)}
            </div>
            <div className="hover last" onClick={(e)=> {logout(e)}}>
                Logout
            </div>
          </div>



          </div>
        ) : (
          <div className="last itemsMenuMobile">
            <Link to="/login">
                Login
            </Link>
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;
