import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Login.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';
import { useTranslation } from "react-i18next";


function ResetPassword(props) {

  let history = useHistory();

  const { t } = useTranslation('common');

  const [myEmail, setMyEmail] = React.useState("");

  const [message, setMessage] = React.useState(false);

  useEffect(() => {

    window.scrollTo(0, 0)

  }, []);

  function sendReset(e) {
    e.preventDefault();

    if( myEmail ) {
      let params = {
        email: myEmail
      }
      axios.post(process.env.REACT_APP_API_URL + '/reset-password/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              sendEmail(response.data.user_id);
              setMessage(true);
            }
            else {
              // NO
            }
          });
    }
  }

  function sendEmail( user_id) {

    let input = {
      user_id: user_id,
      url: window.location.host
    };

    axios.post(process.env.REACT_APP_API_URL + '/send-email-reset-password/', input);

  }

  return (
    <div className="Login">
      <Header />
      <div className="content">
        <div className="card">
          <div className="card-header">
            RESET PASSWORD
          </div>
          <div className="card-body">
            <div className="content-input" style={message ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="email">Email</label>
              <input
                  type="text"
                  className="email"
                  name="email"
                  onChange={(ev) => setMyEmail(ev.target.value)}
                  required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
            </div>
            <div className="content-button" style={message ? { display: 'none' } : { display: 'block' } }>
              <button type="button" className="button" onClick={(e)=> {sendReset(e)}}>
                {t('register.reset')}
              </button>
            </div>
            <p style={!message ? { display: 'none' } : { display: 'block' } }>{t('register.messageReset')}</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ResetPassword;
