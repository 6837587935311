import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Login.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';
import { useTranslation } from "react-i18next";


function Register(props) {

  let history = useHistory();

  const { t } = useTranslation('common');

  const [state, setState] = React.useState({
                                        name: "",
                                        surname: "",
                                        nickname: "",
                                        email: "",
                                        password: "" });

  const [stateErrorEmail, setStateErrorEmail] = React.useState({errorEmail: false});

  const [stateErrorPassword, setStateErrorPassword] = React.useState({errorPassword: false});

  const [stateErrorNickname, setStateErrorNickname] = React.useState(false);

  const [stateErrorSurname, setStateErrorSurname] = React.useState(false);

  const [stateErrorName, setStateErrorName] = React.useState(false);

  const [stateError, setStateError] = React.useState({error: false, errorString: ""});

  const [stateSuccess, setStateSuccess] = React.useState(false);

  useEffect(() => {

    window.scrollTo(0, 0)

  }, []);

  function onChangeMyError( error ) {
    setStateError(error);
  }

  function sendRegister(e) {
    e.preventDefault();

    setStateErrorEmail({errorEmail: false});
    setStateErrorPassword({errorPassword: false});
    setStateError({error: false, errorString: ""});
    setStateErrorNickname(false);
    setStateErrorSurname(false);
    setStateErrorName(false);

    if( state.email && (state.password || state.password.length > 8) ) {

      let getLang = 'en';
      var userLang = navigator.language || navigator.userLanguage;
      if( userLang == 'it' || userLang == 'it-IT') {
        getLang = 'it';
      }

      let params = {
        name: state.name,
        surname: state.surname,
        nickname: state.nickname,
        email: state.email,
        password: state.password,
        lang: getLang,
        from: "web"
      }
      axios.post(process.env.REACT_APP_API_URL + '/register/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              /* sendEmail(response.data.id, response.data.otp); */
              setStateSuccess(true);
            }
            else {
              // NO
              setStateError({error: true, errorString: response.data.error.rls});
            }
          });
    }
    else {
      if( !state.email ) {
        setStateErrorEmail({errorEmail: true});
      }
      if( !state.password || state.password < 8 ) {
        setStateErrorPassword({errorPassword: true});
      }
      if( !state.nickname ) {
        setStateErrorNickname(true);
      }
      if( !state.surname ) {
        setStateErrorSurname(true);
      }
      if( !state.name ) {
        setStateErrorName(true);
      }
    }
  }

  // function sendEmail( user_id, otp) {

  //   let input = {
  //     user_id: user_id,
  //     email: state.email,
  //     otp: otp,
  //     url: window.location.host
  //   };

  //   axios.post(process.env.REACT_APP_API_URL + '/send-email-register/', input);

  //   let inputAdmin = {
  //     email: state.email,
  //     ctrl: 'registrazione'
  //   };

  //   // axios.post(process.env.REACT_APP_API_URL + '/send-email-admin/', inputAdmin);

  // }

  return (
    <div className="Login">
      <Header />
      <div className="content">
        <div className="card">
          <div className="card-header">
            {t('register.title')}
          </div>
          <div className="card-body">
          <div className="content-input" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="name">Nome</label>
              <input
                  type="text"
                  className="email"
                  name="name"
                  onChange={(ev) => setState({...state, name: ev.target.value})}
                  style={!stateErrorName ? { marginBottom: '30px' } : { marginBottom: 0 } }/>
                  <p className="error" style={!stateErrorName ? { display: 'none' } : { display: 'block' } }><small>{t('register.errorName')}</small><br /><br /></p>
            </div>
            <div className="content-input" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="surname">Cognome</label>
              <input
                  type="text"
                  className="email"
                  name="surname"
                  onChange={(ev) => setState({...state, surname: ev.target.value})}
                  style={!stateErrorSurname ? { marginBottom: '30px' } : { marginBottom: 0 } }/>
                  <p className="error" style={!stateErrorSurname ? { display: 'none' } : { display: 'block' } }><small>{t('register.errorSurname')}</small><br /><br /></p>
            </div>
            <div className="content-input" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="nickname">Nickname</label>
              <input
                  type="text"
                  className="email"
                  name="nickname"
                  onChange={(ev) => setState({...state, nickname: ev.target.value})}
                  style={!stateErrorNickname ? { marginBottom: '30px' } : { marginBottom: 0 } } />
                  <p className="error" style={!stateErrorNickname ? { display: 'none' } : { display: 'block' } }><small>{t('register.errorNickname')}</small><br /><br /></p>
            </div>
            <div className="content-input" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="email">Email</label>
              <input
                  type="text"
                  className="email"
                  name="email"
                  onChange={(ev) => setState({...state, email: ev.target.value})}
                  required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  style={!stateErrorEmail.errorEmail ? { marginBottom: '30px' } : { marginBottom: 0 } } />
            </div>
            <p className="error" style={!stateErrorEmail.errorEmail ? { display: 'none' } : { display: 'block' } }><small>{t('login.errorEmailOne')}</small><br /><br /></p>
            <div className="content-input" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="password">Password</label>
              <input
                    type="password"
                    className="password"
                    name="password"
                    onChange={(ev) => setState({...state, password: ev.target.value})}
                    style={!stateErrorPassword.errorPassword ? { marginBottom: '30px' } : { marginBottom: 0 } }/>
              <p className="error" style={!stateErrorPassword.errorPassword ? { display: 'none' } : { display: 'block' } }><small>{t('login.errorPasswordOne')}</small><br /><br /></p>
            </div>
            <p className="terms" dangerouslySetInnerHTML={{__html: t('register.policy')}}  style={stateSuccess ? { display: 'none' } : { display: 'block' } }/><br /><br />

            <p className="error" style={!stateError.error ? { display: 'none' } : { display: 'block' } }><small>{t('login.' + stateError.errorString)}</small><br /><br /></p>
            <p className="success" dangerouslySetInnerHTML={{__html: t('login.successBis')}} style={!stateSuccess ? { display: 'none' } : { display: 'block' } } /><br /><br />
            <div className="content-button" style={stateSuccess ? { display: 'none' } : { display: 'block' } }>
              <button type="button" className="button" onClick={(e)=> {sendRegister(e)}}>
                {t('register.title')}
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Register;
