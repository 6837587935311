import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../../scss/Error.scss';
import { useTranslation } from "react-i18next";

function Error(props) {

  const { t } = useTranslation('common');

  const [myError, setMyError] = React.useState("");

  useEffect(() => {
    setMyError(props.params);
  }, []);

  return (
    <div className="Error" style={myError != "" ? { display: 'block' } : { display: 'none' } }>
      { myError }
    </div>
  );
}

export default Error;
