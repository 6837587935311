import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import '../scss/Page.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';

function Cookies() {

  useEffect(() => {

    window.scrollTo(0, 0)

  }, []);

  return (
    <div className="Page">
      <div>
        <Header />
        <div className="cover">
          <h1>Cookies</h1>
        </div>
        <div className="content content-cookies">
          <div>
            <div>
              <p>
                fanta1.it configura i suoi cookie per fare in modo che
                la navigazione dell'utente sia il più soddisfacente possibile.
                È la maniera migliore per l'utente di affacciarsi al tipo di
                contenuto che più gli interessa e che offre fanta1.it.
              </p>
              <h3>Cosa sono i Cookie?</h3>
              <p>
                Un cookie è un file di servizio che si scarica sul computer,
                Smartphone o Tablet dell'utente. Questo file si utilizza per
                recuperare l'informazione relativa alla navigazione dell'utente,
                in modo tale da non perdere la sessione di registro, migliorare
                il tempo di caricamento del sito web, non mostrare informazione
                che l'utente già conosce, etc.
              </p>
              <h3>I cookie di fanta1.it</h3>
              <p>
                fanta1.it utilizza questo tipo di cookie per
                offrire un miglior servizio ai suoi utenti:
              </p>
              <ul style={ { listStyleType: 'square' } }>
                <li>
                  <p>
                    Cookie strettamente necessari: sono necessari per offrire i
                    servizi che l'usuario richiede di ricevere. Se questi cookie
                    vengono disattivati, l'utente non potrà ricevere in maniera
                    corretta i contenuti e i servizi di fanta1.it;
                  </p>
                </li>
                <li>
                  <p>
                    Cookie per l'analisi delle prestazioni: si utilizzano per
                    il monitoraggio e l'analisi statistica del comportamento degli utenti;
                  </p>
                </li>
                <li>
                  <p>
                    Cookie pubblicitari: necessari per gestire gli spazi
                    pubblicitari seguendo diversi criteri, come la frequenza in
                    cui gli annunciano vengono mostrati;
                  </p>
                </li>
                <li>
                  <p>
                    Cookie per l'analisi delle funzionalità: gestiscono gli
                    spazi pubblicitari seguendo il profilo specifico dell'utente.
                  </p>
                </li>
              </ul>
              <p>
                La disattivazione dei cookie per l'analisi delle prestazioni,
                delle funzionalità e pubblicitari non influirà sul funzionamento
                del sito web, però si ripercuoterà sull'esito degli annunci
                mostrati che permettono migliorare i servizi di fanta1.it
                e ottenere introiti che offrono la possibilità di offrire
                gratuitamente molti servizi e contenuti.<br /><br />
                Al momento i cookies utilizzati sono:
              </p>
              <ul style={ { listStyleType: 'square' } }>
                <li>
                  <h4>Google Analytics</h4>
                  <ul style={ { listStyleType: 'square' } }>
                    <li><p>Responsabile: Google Analytics</p></li>
                    <li><p>Tipo: Analitici</p></li>
                    <li><p>Descrizione: Servizio di analisi per il rilevamento e l’ottenimento di informazioni sul traffico del sito web.</p></li>
                    <li><p>Responsabile: Google Analytics</p></li>
                    <li><p>Maggiori Informazioni:
                    <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"  rel="noreferrer">QUI</a></p></li>
                  </ul>
                </li>
              </ul>
              <p>
                L'informazione che fanta1.it ottiene con l'uso di questi
                cookie (riferita al pc dell'utente) potrà essere abbinata con i
                dati personali dell'utente, nel caso in cui sia registrato su
                fanta1.it.
              </p>
              <h3>Disattivazione di cookie</h3>
              <p>
                L'utente potrà scegliere in qualsiasi momento quali cookie vuole
                che funzionino sul sito web mediante la configurazione del browser:
              </p>

              <ul style={ { listStyleType: 'square' } }>
                <li>
                  <p>
                    Chrome, da
                    <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=it">
                      QUI
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Explorer, da
                    <a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies">
                      QUI
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Firefox, da
                    <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
                      QUI
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    Safari, da
                    <a href="https://support.apple.com/kb/ph21411?locale=it_IT">
                      QUI
                    </a>
                  </p>
                </li>
              </ul>
              <p>
                fanta1.it non si responsabilizza del contenuto e della
                veridicità delle politiche di privacy dei terzi inclusi in questa
                politica di cookie. Per qualsiasi dubbio sulla politica di cookie,
                è possibile mettersi in contatto con fanta1.it scrivendo
                a gdpr[chiocciola]fanta1.it.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default Cookies;
