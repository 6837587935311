import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Login.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';
import { useTranslation } from "react-i18next";


function SetPassword(props) {

  let history = useHistory();

  const { t } = useTranslation('common');

  const [state, setState] = React.useState({
                                              passwordOne: "",
                                              passwordTwo: ""});

  const [message, setMessage] = React.useState(false);

  const [StateErrorpasswordOneEmpty, setStateErrorpasswordOneEmpty] = React.useState(false);

  const [StateErrorpasswordTwoEmpty, setStateErrorpasswordTwoEmpty] = React.useState(false);

  const [StateErrorpasswordEmpty, setStateErrorpasswordEmpty] = React.useState(false);

  const [userId, setId] = React.useState(false);

  useEffect(() => {

    window.scrollTo(0, 0)

    const queryParameters = new URLSearchParams(window.location.search);

    let token = queryParameters.get("token");
    if( token != "" ) {
      let params = {
        token: token
      }
      axios.post(process.env.REACT_APP_API_URL + '/get-reset-password/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setId(response.data.user_id);
            }
            else {
              // NO
              window.location.href = "/";
            }
          });
    }
    else {
      window.location.href = "/";
    }

  }, []);

  function sendPassword(e) {
    e.preventDefault();

    setStateErrorpasswordOneEmpty(false);
    setStateErrorpasswordTwoEmpty(false);
    setStateErrorpasswordEmpty(false);

    if( state.passwordOne != "" && 
        state.passwordTwo != "" && 
        state.passwordOne == state.passwordTwo && 
        (state.passwordOne || state.passwordOne.length > 8)) {
      let params = {
        passwordOne: state.passwordOne,
        passwordTwo: state.passwordTwo,
        user_id: userId
      }

      axios.post(process.env.REACT_APP_API_URL + '/set-password/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              setMessage(true);
            }
            else {
              // NO
            }
          });
    }
    else {
      if( state.passwordOne == "" ) {
        setStateErrorpasswordOneEmpty(true);
      }
      if( state.passwordTwo == "" ) {
        setStateErrorpasswordTwoEmpty(true);
      }
      if( state.passwordOne != state.passwordTwo ) {
        setStateErrorpasswordEmpty(true);
      }
    }
  }

  return (
    <div className="Login">
      <Header />
      <div className="content">
        <div className="card">
          <div className="card-header">
            RESET PASSWORD
          </div>
          <div className="card-body">
            <div className="content-input" style={message ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="password">{t('reset.passwordOne')}</label>
              <input
                    type="password"
                    className="password"
                    name="password"
                    onChange={(ev) => setState({...state, passwordOne: ev.target.value})}
                    style={!StateErrorpasswordOneEmpty ? { marginBottom: '30px' } : { marginBottom: 0 } } />
              <p className="error" style={!StateErrorpasswordOneEmpty ? { display: 'none' } : { display: 'block' } }><small>{t('reset.errorPasswordOneEmpty')}</small><br /><br /></p>
            </div>
            <div className="content-input" style={message ? { display: 'none' } : { display: 'block' } }>
              <label htmlFor="password">{t('reset.passwordTwo')}</label>
              <input
                    type="password"
                    className="password"
                    name="password"
                    onChange={(ev) => setState({...state, passwordTwo: ev.target.value})}
                    style={!StateErrorpasswordTwoEmpty ? { marginBottom: '30px' } : { marginBottom: 0 } }  />
              <p className="error" style={!StateErrorpasswordTwoEmpty ? { display: 'none' } : { display: 'block' } }><small>{t('reset.errorPasswordTwoEmpty')}</small><br /><br /></p>
            </div>
            <p className="error" style={!StateErrorpasswordEmpty ? { display: 'none' } : { display: 'block' } }><small>{t('reset.errorPasswordEmpty')}</small><br /><br /></p>
            <div className="content-button" style={message ? { display: 'none' } : { display: 'block' } }>
              <button type="button" className="button" onClick={(e)=> {sendPassword(e)}}>
                {t('register.reset')}
              </button>
            </div>
            <p style={!message ? { display: 'none' } : { display: 'block' } }>{t('reset.messageResetSuccess')}</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SetPassword;
