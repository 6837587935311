import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './partials/Header';
import { useTranslation } from "react-i18next";
import '../scss/Backoffice.scss';

function Backoffice() {

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [drivers, setDrivers] = React.useState([]);

  const [actions, setActions] = React.useState([]);

  const [bonus, setBonus] = React.useState([]);

  const [races, setRaces] = React.useState([]);

  const [classification, setClassification] = React.useState([]);

  const [race, setRace] = React.useState(0);

  const [errorRace, setErrorRace] = React.useState('none');

  const [allUsers, setRegister] = React.useState(0);

  useEffect(() => {

      if( !localStorage.getItem("fm_info") ) {
        window.location.href = "/";
      }

      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      if( userInfo.level != 78 ) {
        window.location.href = "/";
      }

      let params = {
        token: localStorage.getItem("fm_session"),
        id: userInfo.id,
        level: userInfo.level
      }

      if( myCtrl ) {
        axios.post(process.env.REACT_APP_API_URL + '/backoffice', params )
            .then(response => {
              if(response.data.error.status === false) {
                // OK
                setDrivers(response.data.rls.drivers);
                setActions(response.data.rls.actions);
                setBonus(response.data.rls.bonus);
                setRaces(response.data.rls.races);
                setClassification(response.data.rls.classification);
                setRegister(response.data.rls.allUsers);
              }
              else {
                // NO
                // setStateError({error: true, errorString: response.data.error.rls});
              }
              return;
            });
      }

      setMyCtrl(false);

    }, [myCtrl]);

  function setRaceState(e, race_id) {
    e.preventDefault();
    setRace(race_id);
    setErrorRace('none');
    let elements = document.querySelectorAll('.content-races .active');
    if( elements.length > 0 ) {
      [].forEach.call(elements, function(el) {
          el.classList.remove("active");
      });
    }
    e.currentTarget.classList.toggle('active');
  }

  function setPoint(e, driver_id, action_id) {
    e.preventDefault();
    if( race == 0 ) {
      setErrorRace('block');
    }
    else {

      e.currentTarget.classList.toggle('active');

      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      let params = {
        token: localStorage.getItem("fm_session"),
        id: userInfo.id,
        level: userInfo.level,
        driver_id: driver_id,
        action_id: action_id,
        race_id: race
      };

      axios.post(process.env.REACT_APP_API_URL + '/backoffice/set-point', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK

            }
            else {
              // NO
              // setStateError({error: true, errorString: response.data.error.rls});
            }
            return;
          });
    }
  }

  return (
    <div className="Backoffice">
      <Header />
      <div className="content">
        <br /><br /><br /><br />
        <h3 className="allUsers">{ allUsers } Utenti registrati</h3>
        <div className="content-races">
        {races && races.length > 0 ? (
          races.map(function(item, r) {
            return (
              <div key={r} onClick={(e)=> {setRaceState(e, item.id)}}>
                { item.race } { item.year }
              </div>
            );
           })
        ) : (
          <div></div>
        )}
        </div>
        <div className="error-race" style={{ display: errorRace }}>
          Ricordati di selezionare il Gran Premio!
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              {actions && actions.length > 0 ? (
                actions.map(function(item, a) {
                  return (
                    <th key={a}>
                      { item.what }
                    </th>
                  );
                 })
              ) : (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {drivers && drivers.length ? (
              drivers.map(function(item, i) {

                return (
                  <tr key={i}>
                    <td className="headcol">{ item.surname }</td>
                    { actions.map(function(action, k) {
                      return (
                        <td className="action" onClick={(e)=> {setPoint(e, item.id, action.id)}}>{ action.point }</td>
                      )})}
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
        <br /><br />
        <table>
          <tbody>
              {bonus && bonus.length > 0 ? (
                bonus.map(function(item, b) {
                  return (
                    <tr key={b}>
                      <td style={{ width: '150px' }}>
                        { item.what }
                      </td>
                      <td className="action" style={{ width: '150px' }} onClick={(e)=> {setPoint(e, 0, item.id)}}>
                        { item.point }
                      </td>
                    </tr>
                  );
                 })
              ) : (
                <td></td>
              )}
          </tbody>
        </table>
        <br /><br />
        <table>
          <thead>
            <tr>
              <th></th>
              {classification && classification.length > 0 ? (
                classification.map(function(classificat, a) {
                  return (
                    <th key={a}>
                      { classificat.what }
                    </th>
                  );
                 })
              ) : (
                <th></th>
              )}
            </tr>
          </thead>
          <tbody>
            {drivers && drivers.length ? (
              drivers.map(function(item, i) {
                return (
                  <tr key={i}>
                    <td className="headcol">{ item.surname }</td>
                    { classification.map(function(classificat, c) {
                      return (
                        <td className="action" onClick={(e)=> {setPoint(e, item.id, classificat.id)}}>{ classificat.point }</td>
                      )})}
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Backoffice;
