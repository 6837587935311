import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Page.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';

function ConfirmAccount(props) {

  let history = useHistory();

  useEffect(() => {

    window.scrollTo(0, 0);

    const queryParameters = new URLSearchParams(window.location.search);

    let otp = queryParameters.get("otp");

    if( !otp ) {
      window.location.href = "/";
    }

    let value = {
      otp: otp
    }
    axios.post(process.env.REACT_APP_API_URL + '/set-account/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            setTimeout( () => {
              window.location.href = "/login";
            }, 5000);

          }
          else {
            // NO
            localStorage.removeItem('fm_info');
            localStorage.removeItem('fm_session');
            window.top.location.href = '/';
          }
        });

  }, []);

  return (
    <div className="Page">
      <div>
        <Header />
        <div className="cover">
          <h1>Account confermato</h1>
        </div>
        <div className="content content-cookies">
          <div className="content-elms">
            <h2 className="text-center col-lg-12">Grazie!</h2>
            <p className="text-center">Il tuo account è adesso abilitato!</p>
          </div>
        </div>
      </div>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default ConfirmAccount;
