import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import '../scss/Page.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';

function ConfirmLega(props) {

  let history = useHistory();

  const [nameLega, setNameLega] = React.useState("");

  useEffect(() => {

    window.scrollTo(0, 0);

    const queryParameters = new URLSearchParams(window.location.search);

    let email = queryParameters.get("email");
    let lega = queryParameters.get("lega");

    if( !email ) {
      window.location.href = "/";
    }

    // console.log(queryParameters);
    // console.log(email);
    // console.log(lega);
    // return;

    let value = {
      email: email,
      lega_id: lega
    }
    axios.post(process.env.REACT_APP_API_URL + '/set-lega/', value )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            setNameLega(response.data.rls);
          }
          else {
            // NO
            localStorage.removeItem('fm_info');
            localStorage.removeItem('fm_session');
            window.top.location.href = '/';
          }
        });

  }, []);

  return (
    <div className="Page">
      <div>
        <Header />
        <div className="cover">
          <h1>Account confermato</h1>
        </div>
        <div className="content content-cookies">
          <div className="content-elms">
            <h2 className="text-center col-lg-12">Grazie!</h2>
            <p className="text-center">Adesso fai farte della lega <strong>{ nameLega }</strong></p>
          </div>
        </div>
      </div>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default ConfirmLega;
