import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import '../../scss/ModalCreateTeam.scss';

function ModalCreateTeam(props) {

  const { t } = useTranslation('common');

  const [myModal, setMyModal] = React.useState('none');

  const [muchDrivers, setMuchDrivers] = React.useState('none');

  const [myCtrl, setMyCtrl] = React.useState(true);

  const [drivers, setDrivers] = React.useState([]);

  const [driverState, setDriverState] = React.useState([]);

  useEffect(() => {

    setMyModal(props.open);

    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id
    }

    if( myCtrl ) {
      let elements = document.getElementsByClassName('activeTeam');
      if( elements.length > 0 ) {
        [].forEach.call(elements, function(el) {
            el.classList.remove("activeTeam");
        });
      }
      axios.post(process.env.REACT_APP_API_URL + '/drivers', params )
          .then(response => {

            if(response.data.error.status === false) {
              // OK
              setDrivers(response.data.rls);
            }
            else {
              // NO
              // setStateError({error: true, errorString: response.data.error.rls});
            }
            return;
          });
    }

    setMyCtrl(false);

  });

  function setDriver(e, name, driver_id) {
    if( e.currentTarget.classList.contains('activeTeam') || document.querySelectorAll('.activeTeam').length < 2 ) {
      e.currentTarget.classList.toggle('activeTeam');
      if( !e.currentTarget.classList.contains('activeTeam') ) {
        let allMyDrivers = driverState;
        let newArrayDriver = [];
        allMyDrivers.map(function(item, i) {
          if( item.driver_name != name && item.driver_id != driver_id ) {
            newArrayDriver.push({
              driver_name: item.driver_name,
              driver_id: item.driver_id
            })
          }
        });
        setDriverState(newArrayDriver);
      }
      else {
        setDriverState([...driverState, {
          driver_name: name,
          driver_id: driver_id
        }]);
      }
      return;
    }
    else {
      setMuchDrivers("block");
      setTimeout(() => {
        setMuchDrivers("none");
      }, 3000);
      return;
    }
  };

  function confirmMyTeam(e) {
    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    let params = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id,
      drivers: driverState
    };

    axios.post(process.env.REACT_APP_API_URL + '/create-teams', params )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            window.location.href = "/dashboard";
            // props.createMyTeam(e, 'none');
          }
          else {
            // NO
            // setStateError({error: true, errorString: response.data.error.rls});
          }
          return;
        });
  };

  return (
    <div className="modal" style={{ display: myModal }}>
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="modal-header">
          {t('modalCreateTeam.title')}
          <div className="closeModal" onClick={(e)=> {props.createMyTeam(e, 'none')}}>
            X
          </div>
        </div>
        <div className="modal-body">
        {drivers && drivers.length ? (
          drivers.map(function(item, i) {
            return (
              <div className={'list'} key={ i } onClick={(e)=> {setDriver(e, item.name + " " + item.surname, item.id)}}>
                { item.name } { item.surname }
              </div>
            );
           })
        ) : (
          <div>
            {t('dashboard.noResult')}
          </div>
        )}
        {driverState && driverState.length ? (
          <div className="content-button">
            <br /><br />
            <strong>{t('modalCreateTeam.listDriver')}</strong>
            <ul className="driver-choose">
              {driverState.map(function(item, i) {
                return (
                  <li className="driver" key={ i }>
                    { item.driver_name }
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div></div>
        )}
        {driverState && driverState.length && driverState.length == 2 ? (
          <div className="content-button">
            <button type="button" className="button" onClick={(e)=> {confirmMyTeam(e)}}>
              {t('dashboard.createTeam')}
            </button>
          </div>
        ) : (
          <div></div>
        )}
        </div>
        <div className="much-drivers" style={{ display: muchDrivers }}>
          {t('modalCreateTeam.muchDrivers')}
        </div>
      </div>
    </div>
  );
}

export default ModalCreateTeam;
