import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import '../scss/Page.scss';
import Header from './partials/Header';
import Footer from './partials/Footer';

function PrivacyPolicy() {

  let history = useHistory();


  useEffect(() => {

    window.scrollTo(0, 0);

  }, []);

  return (
    <div className="Page">
      <div>
        <Header />
        <div className="cover">
          <h1>Policy Privacy</h1>
        </div>
        <div className="content">
          <div>
            <div>
              <p>
                La presente Politica sulla protezione dei dati personali
                disciplina l'accesso e l’utilizzo dei servizi offerti sul
                Sito da parte degli Utenti in qualità di titolari di dati
                personali suscettibili di protezione ai sensi delle normative
                vigenti in materia di protezione dei dati personali.<br /><br />
                fanta1.it garantisce l’adempimento integrale delle normative
                vigenti in materia di protezione dei dati personali e degli
                obblighi di riservatezza propri della sua attività.
              </p>
              <ol>
                <li>
                  <h3>Titolare del trattamento</h3>
                  <p>
                    Si informa che il Titolare del trattamento dei suoi dati
                    personali sono gli ideatori e creatori della piattaforma
                    fanta1.it, indirizzo e-mail gdpr[chiocciola]fanta1.it.
                  </p>
                </li>
                <li>
                  <h3>fanta1.it in qualità di Responsabile del trattamento</h3>
                  <p>
                    L’Utente e consumatore finale (utente), rimane il
                    Titolare del trattamento dei dati personali forniti con
                    motivo dei servizi prestati da fanta1.it, e come
                    tale è obbligato ad adempiere le normative vigenti in
                    materia di protezione dei dati personali, ad ottenere il
                    consenso informato degli interessati al trattamento dei
                    loro dati personali e a fornire tutte le garanzie necessarie
                    per il trattamento dei dati personali.<br /><br />
                    L’Utente rimane in ogni momento responsabile per le immagini
                    pubblicate e i dati personali inseriti sul Sito. Salvo e
                    impregiudicato quanto sopra, fanta1.it si riserva
                    il diritto di ritirare eventuali Contenuti illeciti o illegali.<br /><br />
                    fanta1.it tratterà i dati secondo le clausole
                    contrattuali tipo stabilite dalla Comissione Europea.
                  </p>
                </li>
                <li>
                  <h3>Categorie di dati personali trattati</h3>
                  <p>
                    I dati personali oggetto del trattamento sono i dati
                    personali forniti dall'Utente a fanta1.it al
                    momento della compilazione del modulo di registrazione
                     on-line, attraverso i social network, attraverso il
                     Servizio clienti o con qualsiasi altro mezzo consentito
                     (chat, telefono, e-mail).<br /><br />
                     In caso di utilizzo di social network, risulta di
                     applicazione la politica sulla privacy ivi stabilita
                     caso per caso.<br /><br />
                  </p>
                </li>
                <li>
                  <h3>Carattere obbligatorio o facoltativo delle informazioni fornite dall’Utente e veridicità dei dati</h3>
                  <p>
                    I campi contrassegnati da un asterisco (*) nel modulo di
                    registrazione da compilare a cura dell'Utente sono
                    necessari per dar seguito alla sua richiesta, essendo
                    facoltativo l'inserimento dei dati in tutti gli altri campi.<br /><br />
                    Per quanto riguarda i dati forniti con qualsiasi altro mezzo,
                    al momento del loro trattamento fanta1.it è,
                    inoltre, tenuta a indicare agli Utenti l'obbligatorietà
                    o meno del corrispondente inserimento.<br /><br />
                    L'Utente garantisce che i dati personali forniti a
                    fanta1.it sono veritieri e aggiornati,
                    impegnandosi a comunicare qualsiasi modifica degli
                    stessi, essendo l'Utente l’unico responsabile in caso
                    di inesattezza o falsità dei dati forniti per eventuali
                    danni e perdite cagionati a fanta1.it o a terzi
                    con motivo dell'utilizzo dei servizi offerti da
                    fanta1.it. fanta1.it a sua volta dichiara
                    che i dati personali ricavati sono in ogni momento
                    adeguati, pertinenti e commisurati rispetto all’ambito,
                    alle finalità specifiche e alla natura dei servizi prestati.
                  </p>
                </li>
                <li>
                  <h3>Finalità e basi giuridiche del trattamento</h3>
                  <p>
                    fanta1.it è autorizzata a trattare i dati personali
                    degli Utenti per le finalità summenzionate allo scopo di
                    adempiere ai propri obblighi contrattuali derivanti da
                    richieste inoltrate dagli Utenti.<br /><br />
                    I dati personali degli Utenti forniti a fanta1.it
                    con qualsiasi mezzo vengono raccolti, inoltre, per le
                    seguenti finalità:
                  </p>
                  <ul style={ { listStyleType: 'square' } }>
                    <li><p>Analisi dei dati per scopi formativi e/o statistici;
                    attività fondamentali di analisi del contenuto delle
                    informazioni fornite ed elaborazione di profili destinati
                    alla corretta gestione delle attività e dei servizi
                    forniti da fanta1.it.</p></li>
                  </ul>
                  <p>
                    fanta1.it è autorizzata a trattare i dati personali
                    degli Utenti per le finalità di cui sopra al sussistere
                    un interesse legittimo di fanta1.it, espressamente
                    riconosciuto dalle normative vigenti in materia di
                    protezione dei dati personali, alla corretta prestazione
                    del servizio.<br /><br />
                    In aggiunta, i dati personali degli Utenti forniti a
                    fanta1.it con qualsiasi mezzo vengono ulteriormente
                    raccolti per le seguenti finalità:
                  </p>
                  <ul style={ { listStyleType: 'square' } }>
                    <li>
                      <p>
                        Invio di comunicazioni elettroniche promozionali e
                        informative sui servizi prestati dalle aziende con cui
                        fanta1.it mantiene accordi commerciali
                        (incluse comunicazioni commerciali proprie, di Utenti
                        professionali e terzi), inerenti al settore delle gare, data la
                        natura e l’oggetto dei servizi prestati da fanta1.it.
                        Agli effetti della presente Informativa, per prodotti
                        o servizi inerenti al settore
                        si intendono tutti i prodotti e i servizi presenti
                        e futuri inclusi sul Sito, nonché qualsiasi genere
                        di prodotto, servizio o campagna specificamente
                        destinato a eventi automobilistici.
                      </p>
                      <p>
                        fanta1.it ha facoltà di inviare dette comunicazioni
                        elettroniche promozionali per l’intera vigenza del
                        rapporto tra Utente e fanta1.it, sempre e quando
                        l’Utente non manifesti il contrario.<br /><br />
                        fanta1.it mette a disposizione una casella
                        email per le richieste di disattivazione di invio
                        email e la si ritrova la punto 1.<br /><br />
                        fanta1.it è autorizzata a trattare i dati
                        personali degli Utenti per le finalità di cui sopra,
                        come espressamente riconosciuto dalla legislazione vigente,
                        (i) qualora l'Utente abbia prestato il suo consenso e/o
                        (ii) qualora le informazioni inviate siano inerenti a
                        prodotti e servizi simili a quelli oggetto di vendite
                        o negoziazioni anteriori, a condizione che l'Utente
                        non abbia optato per smettere di ricevere tali comunicazioni.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Decisioni automatizzate</h3>
                  <p>
                    fanta1.it dichiara di non adottare decisioni
                    suscettibili di influenzare l'Utente basate esclusivamente
                    sul trattamento automatizzato dei suoi dati personali.
                    Tutti i processi decisionali associati alle finalità del
                    trattamento sopra descritte si eseguono con l’intervento umano.
                  </p>
                </li>
                <li>
                  <h3>Trasferimento di dati personali a terzi</h3>
                  <p>
                    fanta1.it ha facoltà, di trasferire i dati personali
                    degli Utenti ai seguenti destinatari e per le ragioni
                    indicate a continuazione:
                  </p>
                  <ul style={ { listStyleType: 'square' } }>
                    <li>
                      <p>
                        Pubbliche autorità, per l'adempimento degli obblighi
                        di legge ai quali fanta1.it è soggetta con motivo
                        della sua attività;
                      </p>
                    </li>
                  </ul>
                  <p>
                    fanta1.it si impegna a informare tempestivamente
                    l’Utente in caso di futuri trasferimenti di dati personali.
                  </p>
                </li>
                <li>
                  <h3>Periodo di conservazione</h3>
                  <p>
                    fanta1.it ha facoltà di conservare i dati personali
                    dell'Utente per l’intera vigenza del rapporto con
                    fanta1.it e, una volta estinto tale rapporto e
                    indipendentemente dalla causa, fino allo scadere dei termini
                    di prescrizione legalmente applicabili in relazione alle
                    finalità e all’interesse legittimo per il trattamento dei
                    suoi dati personali.<br /><br />
                    Una volta estinto il rapporto, fanta1.it ha facoltà
                    di proseguire l’ulteriore trattamento dei dati personali
                    esclusivamente allo scopo di certificare l'adempimento
                    dei propri obblighi legali o contrattuali se c’è ne fossero.
                    Una volta decorsi detti termini di prescrizione, i dati
                    personali dell’Utente devono essere eliminati definitivamente
                    o, in alternativa, anonimizzati.
                  </p>
                </li>
                <li>
                  <h3>Esercizio dei diritti degli interessati e reclami</h3>
                  <p>
                    L'Utente ha facoltà di esercitare i propri diritti di accesso,
                    rettifica, cancellazione, portabilità, limitazione del
                    trattamento e/o opposizione al trattamento.<br /><br />
                    Tali diritti sono esercitabili modificando opportunamente
                    le proprie impostazioni del Sito. In caso di problemi
                    per l'effettivo esercizio online dei propri diritti o
                    per qualsiasi tipo di dubbio o controversia insorgente
                    sulla nostra Politica sulla protezione dei dati personali,
                    è possibile rivolgersi all’indirizzo e-mail:
                    gdpr[chiocciola]fanta1.it menzionando nell’oggetto
                    il diritto da esercitare.<br /><br />
                    In caso l’Utente consideri che il trattamento dei propri
                    dati personali avvenga in violazione delle normative vigenti
                    o dei suoi diritti in materia, ha facoltà di presentare un
                    reclamo a fanta1.it o al “Garante per la protezione
                    dei dati” italiano, https://www.garanteprivacy.it
                  </p>
                </li>
                <li>
                  <h3>Dati personali di minori</h3>
                  <p>
                    L’inserimento sul Sito dei dati personali di minori e,
                    in particolare, della loro immagine, implica l’obbligo
                    dell'Utente responsabile di tale inserimento di dichiarare
                    espressamente e senza riserve:
                  </p>
                  <ul style={ { listStyleType: 'square' } }>
                    <li><p>Di essere il legittimo titolare dei diritti necessari
                    per disporre dei dati personali e dell'immagine del minore;</p></li>
                    <li><p>Che l'inserimento dei suddetti dati non pregiudica
                    in alcun modo il minore né viola alcuno dei suoi diritti;</p></li>
                    <li><p>E di manlevare e tenere indenne fanta1.it
                    da ogni possibile reclamo o pretesa a tale titolo,
                    assumendosi l’Utente responsabile di tale inserimento
                    la piena responsabilità derivante da tale reclamo o pretesa.</p></li>
                  </ul>
                </li>
                <li>
                  <h3>Sicurezza dei trattamenti</h3>
                  <p>
                    fanta1.it rende noto agli Utenti di aver adottato le
                    misure tecniche e organizzative adeguate stabilite
                    dalle normative vigenti, per garantire la sicurezza
                    dei dati personali ed evitare la loro distruzione,
                    perdita, modifica, divulgazione non autorizzata o accesso,
                    tenuto conto dello stato dell’arte, della natura dei dati
                    personali custoditi e dei rischi ai quali sono esposti.
                  </p>
                </li>
                <li>
                  <h3>Cookie e indirizzi IP</h3>
                  <p>
                    L’Utente acconsente all’uso di marcatori temporanei (cookie)
                    e al monitoraggio degli indirizzi IP.<br /><br />
                    Il sistema di analisi del traffico sul nostro Sito utilizza
                    i cookie e il monitoraggio di indirizzi IP, onde permettere
                    la raccolta di dati a fini statistici, come data della prima
                    visita, numero di visite, data dell'ultima visita, URL e
                    dominio di provenienza, navigatore utilizzato e risoluzione
                    dello schermo. Ciononostante, se così lo desidera,
                    l’Utente ha la possibilità di disattivare e/o eliminare
                    questi cookie seguendo le istruzioni del proprio navigatore
                    Internet.<br /><br />
                    Per ulteriori informazioni, la nostra Politica sui cookie
                    è disponibile cliccando sul seguente link:
                    <Link to="/cookies">
                      https://fanta1.it/cookies
                    </Link>.
                  </p>
                </li>
                <li>
                  <h3>Contatto</h3>
                  <p>
                    È possibile contattare fanta1.it all’indirizzo e-mail
                    o all’indirizzo postale pubblicati sopra.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
