import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import '../../scss/ModalCreateTeam.scss';

function ModalLega(props) {

  const { t } = useTranslation('common');

  const [myModal, setMyModal] = React.useState('none');

  const [state, setState] = React.useState({myLega: "", myFriend: "", id: 0, user_id: 0});

  // const [stateErrorMyLega, setStateErrorMyLega] = React.useState(false);

  const [addUser, setStateAddUser] = React.useState(false);

  const [noUser, setStateNoUser] = React.useState(false);

  const [inviteUser, setStateInviteUser] = React.useState("");

  const [allUserLeague, setAllUserLeague] = React.useState([]);

  const [myInfo, setMyInfo] = React.useState([]);

  const [isMyLega, setIsMyLega] = React.useState(false);

  // const [isPublic, setIsPublic] = React.useState(0);

  const [hideButton, setHideButton] = React.useState(true);

  // const [myCtrlModalLega, setMyCtrlModalLega] = React.useState(true);

  useEffect(() => {
    setMyModal(props.open);
    // setIsPublic(props.isPublic);
    revokeMyFriend();
    setAllUserLeague([]);
    if( props.open == 'block' ) {
      window.scrollTo(0, 0);
      setState({...state, 
        myLega: props.lega.name, 
        id: props.lega.id,
        user_id: props.lega.user_id});

        setIsMyLega(props.isMyLega);

        setHideButton(true);

        // if( !props.isMyLega ) {
        //   setStateAddUser(true);
        // }

        let userInfo = JSON.parse(localStorage.getItem("fm_info"));

        setMyInfo(userInfo);

        let userLeague = [];
    
        let index = 0;
        
        let params = {
          token: localStorage.getItem("fm_session"),
          id: userInfo.id,
          league_id: props.lega.id
        }
    
        axios.post(process.env.REACT_APP_API_URL + '/dashboard/users-league', params )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            let users = response.data.rls;
            users.forEach(element => {
              let paramsUser = {
                token: localStorage.getItem("fm_session"),
                id: userInfo.id,
                user_id: element.id,
                isPublic: props.isPublic
              };
              let drivers = "";
              let points = 0;
              axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', paramsUser )
              .then(responseUser => {
                responseUser.data.rls.drivers.forEach(dvrs => {
                  drivers += dvrs.driver_name + '-';
                  points = parseInt(points) + parseInt(dvrs.points);
                });
                userLeague.push({
                  user: element,
                  drivers: drivers.slice(0, -1),
                  points: points
                });
                index++;
                if( index == users.length ) {
                  userLeague.sort(function(a, b) {
                      return b.points - a.points;
                  });
                  setAllUserLeague(userLeague);
                }
              });
            });
          }
          else {
            // NO
            // onChangeMyError(response.data.error.rls);
          }
          return;
        });

    }
  }, [props]);

  function searchMyFriend(e) {
    setStateNoUser(false);
    setStateAddUser(false);
    if( state.myFriend ) {
      let userInfo = JSON.parse(localStorage.getItem("fm_info"));

      let params = {
        token: localStorage.getItem("fm_session"),
        id: userInfo.id,
        myFriend: state.myFriend
      }
      axios.post(process.env.REACT_APP_API_URL + '/search-friend-lega/', params )
          .then(response => {
            if(response.data.error.status === false) {
              // OK
              if( response.data.rls ) {
                setStateAddUser(true);
                setStateInviteUser(response.data.rls);
              }
              else {
                setStateNoUser(true);
              }
            }
            else {
              // NO
              
            }
          });
    }
  }

  function inviteMyFriend(e) {
    
    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    let input = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id,
      userLogged: userInfo.name + " " + userInfo.surname,
      nameLega: state.myLega,
      lega_id: state.id,
      myFriend: inviteUser,
      url: window.location.host
    }

    axios.post(process.env.REACT_APP_API_URL + '/send-email-invite-friend-lega/', input);

    revokeMyFriend();

  }

  function revokeMyFriend(e) {
    setStateNoUser(false);
    setStateAddUser(false);
    setState({...state, myFriend: ""});
  }

  function partecipateInLega(e, lega_id) {
    let userInfo = JSON.parse(localStorage.getItem("fm_info"));

    let input = {
      token: localStorage.getItem("fm_session"),
      id: userInfo.id,
      lega_id: lega_id
    }

    axios.post(process.env.REACT_APP_API_URL + '/dashboard/add-me-lega/', input)
      .then(response => {
        if(response.data.error.status === false) {
          // OK
          if( response.data.rls ) {
            reloadList();
          }
          else {
            // setStateNoUser(true);
          }
        }
        else {
          // NO
          
        }
    });
  }

  function reloadList() {

    let userLeague = [];

    let index = 0;
    
    let params = {
      token: localStorage.getItem("fm_session"),
      id: myInfo.id,
      league_id: props.lega.id
    }

    axios.post(process.env.REACT_APP_API_URL + '/dashboard/users-league', params )
    .then(response => {
      if(response.data.error.status === false) {
        // OK
        let users = response.data.rls;
        users.forEach(element => {
          let paramsUser = {
            token: localStorage.getItem("fm_session"),
            id: myInfo.id,
            user_id: element.id,
            isPublic: props.isPublic
          };
          let drivers = "";
          let points = 0;
          axios.post(process.env.REACT_APP_API_URL + '/dashboard/my-teams', paramsUser )
          .then(responseUser => {
            responseUser.data.rls.drivers.forEach(dvrs => {
              drivers += dvrs.driver_name + '-';
              points = parseInt(points) + parseInt(dvrs.points);
            });
            userLeague.push({
              user: element,
              drivers: drivers.slice(0, -1),
              points: points
            });
            index++;
            if( index == users.length ) {
              userLeague.sort(function(a, b) {
                  return b.points - a.points;
              });
              setAllUserLeague(userLeague);
            }
          });
        });
      }
      else {
        // NO
        // onChangeMyError(response.data.error.rls);
      }
      return;
    });
  }

  function removeFormLega() {
    if( window.confirm("Vuoi toglierti dalla Lega " + state.myLega + "?") ) {
      let params = {
        token: localStorage.getItem("fm_session"),
        id: myInfo.id,
        league_id: state.id
      }
      axios.post(process.env.REACT_APP_API_URL + '/dashboard/remove-from-single-league', params )
        .then(response => {
          if(response.data.error.status === false) {
            // OK
            reloadList();
          }
          else {
            // NO
          }
          return;
        });
    }
  }

  return (
    <div className="modal" style={{ display: myModal }}>
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="modal-header">
          Lega { state.myLega }
          <div className="closeModal" onClick={(e)=> {props.close(e, 'none')}}>
            X
          </div>
        </div>
        <div className="modal-body">
          <div className="contentLega">
            <div>                              
              <div className="content-button buttonRight" style={ hideButton ? { display: 'block' } : { display: 'none' } }>
                  <button type="button" className="button" onClick={(e)=> {partecipateInLega(e, state.id)}}>
                     Partecipa!
                  </button>
                </div>
              {allUserLeague.map(function(item, i) {
                if( item.user.nickname == myInfo.nickname && hideButton ) {
                  setHideButton(false);
                }
                return (
                  <div className={item.user.nickname == myInfo.nickname ? "contentValue iam" : "contentValue"} key={ i }>
                    <span className="position">#{ i + 1 }</span> { item.user.nickname } <span className="viewPoins">{ item.points }</span>
                    <br />
                    <small><i>{ item.drivers.replace('-', ' - ') }</i></small>
                    <div className='removeFromLega' style={ item.user.nickname == myInfo.nickname ? { display: 'block' } : { display: 'none' } }>
                      <span className="fi fi-rr-trash" onClick={(e)=> {removeFormLega()}}></span>
                      </div>
                  </div>
                );
               })}
            </div>
            <div className="marginCenter" style={addUser || !isMyLega ? { display: 'none' } : { display: 'block' } }>
              <div className="content-input">
                <label htmlFor="myTeam">Inserisci l'email dell'amico da aggiungere</label>
                <input
                    type="text"
                    className="input"
                    name="myFriend"
                    onChange={(ev) => setState({...state, myFriend: ev.target.value})}
                    required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
              </div>
              <div className="error" style={!noUser ? { display: 'none' } : { display: 'block' } }>Nessun utente trovato</div>
              <div className="content-button">
                <button type="button" className="button" onClick={(e)=> {searchMyFriend(e)}}>
                  Cerca
                </button>
              </div>
            </div>
            <div className="marginCenter" style={!addUser ? { display: 'none' } : { display: 'block' } }>
              <div className="content-input">
                <label htmlFor="myTeam">Vuoi invitare { inviteUser.name } {inviteUser.surname}?</label>
              </div>
              <br />
              <div className="content-button">
                <button type="button" className="button" onClick={(e)=> {inviteMyFriend(e)}}>
                  Invia Invito
                </button>
                <p className="revoke" onClick={(e)=> {revokeMyFriend(e)}}>Annulla</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalLega;
